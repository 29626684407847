import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    addDialog: false,
    unpairDialog: false,
    pairDialog: false,
    loaderDialog: false,
    loaderMainDialog: false,
    additonalControlDialog: false,
    controlDialog: {
      status: false,
      message: ''
    },
    errorHighDialog: {
      status: false,
      message: ''
    },
    locationDialog: false,
    adminDialog: false,
    staffDialog: false,
    confirmDialog: {
      status: false,
      deviceId: '',
      deviceName: ''
    },
    editDeviceDialog: false,
    editUserDialog: {
      status: false,
      form: {
        username: '',
        email: '',
        password: '',
        logo: '',
        cad: ''
      },
      type: ''
    },
    editAdminDialog: {
      status: false,
      form: {
        username: '',
        email: '',
        password: ''
      },
      type: ''
    },
    alertDialog: {
      status: false,
      message: ''
    },
    snackBar: {
      active: false,
      message: '',
      color: 'success'
    }
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload
    },
    SET_ADD_DIALOG(state, payload) {
      state.addDialog = payload
    },
    SET_UNPAIR_DIALOG(state, payload) {
      state.unpairDialog = payload
    },
    SET_PAIR_DIALOG(state, payload) {
      state.pairDialog = payload
    },
    SET_LOADING_DIALOG(state, payload) {
      state.loaderDialog = payload
    },
    SET_LOADING_MAIN_DIALOG(state, payload) {
      state.loaderMainDialog = payload
    },
    SET_CONTROL_DIALOG(state, payload) {
      state.controlDialog.status = payload.status
      state.controlDialog.message = payload.message
    },
    SET_ERROR_HIGH_DIALOG(state, payload) {
      state.errorHighDialog.status = payload.status
      state.errorHighDialog.message = payload.message
    },
    SET_ADDITIONAL_CONTROL_DIALOG(state, payload) {
      state.additonalControlDialog = payload
    },
    SET_LOCATION_DIALOG(state, payload) {
      state.locationDialog = payload
    },
    SET_ADMIN_DIALOG(state, payload) {
      state.adminDialog = payload
    },
    SET_STAFF_DIALOG(state, payload) {
      state.staffDialog = payload
    },
    SET_CONFIRM_DIALOG(state, payload) {
      state.confirmDialog.status = payload.status;
      state.confirmDialog.deviceId = payload.deviceId;
      state.confirmDialog.deviceName = payload.deviceName;
      state.confirmDialog.deviceNo = payload.deviceNo;
    },
    SET_SNACKBAR(state, payload) {
      state.snackBar.active = payload.active;
      state.snackBar.message = payload.message;
      state.snackBar.color = payload.color;
      setTimeout(() => {
        state.snackBar.active = false
        state.snackBar.message = ""
        state.snackBar.color = "success"
      }, payload.time || 3000);
    },
    SET_ALERT_DIALOG(state, payload) {
      state.alertDialog.status = payload.status
      state.alertDialog.message = payload.message
    },
    SET_EDIT_DEVICE_DIALOG(state, payload) {
      state.editDeviceDialog = payload
    },
    SET_EDIT_USER_DIALOG(state, payload) {
      state.editUserDialog.status = payload.status
      state.editUserDialog.form.username = payload.user.name
      state.editUserDialog.form.email = payload.user.email
      state.editUserDialog.type = payload.type
      state.editUserDialog.form.logo = payload.user.logo
    },
    SET_EDIT_ADMIN_DIALOG(state, payload) {
      state.editAdminDialog.status = payload.status
      state.editAdminDialog.form.username = payload.user.name
      state.editAdminDialog.form.email = payload.user.email
      state.editAdminDialog.type = payload.type
    },
  },
  actions: {
  },
  modules: {
  }
})
