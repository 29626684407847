<template>
  <div>
    <SearchForm />
  </div>
</template>

<script>
import SearchForm from "../components/devices/Search.vue";
export default {
  name: "SearchPairedDevices",
  components: {
    SearchForm,
  },
};
</script>

<style lang="scss" scoped>
</style>