<template>
  <span
    class="praan-button"
    :class="{ transparent: transparent, white: white, red: red }"
  >
    <span v-if="icon != null"
      ><v-icon color="#000" small>{{ icon }}</v-icon></span
    >
    <span>{{ label }}</span>
  </span>
</template>

<script>
export default {
  props: ["label", "transparent", "white", "icon", "red"],
};
</script>

<style lang="scss" scoped>
.praan-button {
  min-width: 160px;
  background: #000;
  display: inline-block;
  padding: 8px 20px;
  font-size: 14px;
  text-align: center;
  color: #fff;
  // border-radius: 31px;
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  &:hover {
    color: #000000 !important;
    background: #fff;
    border: 2px solid #000000;
  }
}
.praan-button.transparent {
  min-width: 120px;
  background: transparent;
  display: inline-block;
  padding: 8px 20px;
  font-size: 14px;
  text-align: center;
  color: #fff;
  // border-radius: 31px;
  border: 2px solid #fff !important;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  &:hover {
    color: #000000 !important;
    background: #fff !important;
    border: 2px solid #000000;
  }
}
.praan-button.red {
  min-width: 120px;
  color: #fd4949 !important;
  font-size: 14px;
  display: inline-block;
  padding: 8px 20px;
  background: #fff !important;
  text-align: center;
  // color: #fff;
  // border-radius: 31px;
  border: 2px solid #fd4949 !important;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  &:hover {
    color: #fff !important;
    background: #fd4949 !important;
    border: 2px solid #fd4949;
  }
}
.praan-button.white {
  min-width: 120px;
  background: #fff;
  display: inline-block;
  padding: 8px 20px;
  font-size: 14px;
  text-align: center;
  color: #000;
  // border-radius: 31px;
  border: 2px solid #000 !important;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  i {
    padding-right: 10px;
    transition: all 0.4s ease-in-out;
  }
  &:hover {
    color: #fff !important;
    background: #000 !important;
    border: 2px solid #fff !important;
    i {
      color: #fff !important;
    }
  }
}
</style>