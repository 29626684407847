<template>
  <div class="device-card">
    <v-row align="center">
      <v-col>
        <h2>
          {{ data.deviceName }}
          <div style="display: inline-block" class="ml-5" v-if="data.health !== 0 || data.controlError != 0">
            <v-icon color="red">mdi-alert-circle-outline</v-icon>
          </div>
        </h2>
        <div class="device-details">
          <div class="f-space">Device Id :</div>
          <div class="f-space1">{{ data.deviceNo }}</div>
        </div>
        <div class="device-details">
          <div class="f-space zone">Zone:</div>
          <div class="f-space1">{{ data.zoneName }}</div>
        </div>
        <!-- <span class="signal"> <SignalBar :network="netstrength" /></span> -->
        <!-- <DeviceStatus :device="data" /> -->
        <!-- <div class="device-loc">
          <v-icon class="mr-1" color="#E37D26">mdi-map-marker</v-icon>
          <span v-if="data.currentLocation != null">
            {{ data.currentLocation.locationName }}</span
          ><span v-else>N/A</span>
        </div> -->
      </v-col>
      <v-col class="text-end">
        <span class="my-2 mx-3 d-inline-block">
          <router-link :to="'/devices/' + data.client._id + '/data/' + data.deviceNo">
            <CustomButton :white="true" label="View Details" />
          </router-link></span><span class="my-2 mx-3 d-inline-block" v-if="access == 2"><span @click="confirm">
            <CustomButton :red="true" label="Remove Device" />
          </span></span>
      </v-col>
    </v-row>
    <ConfirmDialog message="Please make sure to download the previous data before removing the device"
      :deviceId="data._id" :deviceName="data.deviceName" @confirmed="deleteDevice" />
  </div>
</template>

<script>
import CustomButton from "../common/CustomButton.vue";
// import DeviceStatus from "./DeviceStatus.vue";
import ConfirmDialog from "../dialogs/ConfirmDialog.vue";
// import SignalBar from "./SignalBar.vue";
export default {
  props: ["data", "access"],
  components: {
    CustomButton,
    // DeviceStatus,
    ConfirmDialog,
    // SignalBar
  },
  methods: {
    confirm() {
      this.$store.commit("SET_CONFIRM_DIALOG", {
        status: true,
        deviceId: this.data._id,
        deviceName: this.data.deviceName,
        deviceNo:this.data.deviceNo,
      });
    },
    deleteDevice() {
      this.$axios
        .delete("/device", {
          data: {
            deviceNo: this.$store.state.confirmDialog.deviceNo,
          },
        })
        .then((res) => {
          console.log(res);
          this.$store.commit("SET_SNACKBAR", {
            active: true,
            message: "Device Deleted Successfully",
          });
          this.$emit("device_removed");
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style lang="scss" scoped>
.device-card {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(204, 204, 204, 1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(204, 204, 204, 1);
  box-shadow: 0px 0px 10px 0px rgba(204, 204, 204, 1);
  border-radius: 10px;
  padding: 35px;
  margin: 20px 0px;
  width: 100%;
}

.device-details {
  padding-top: 10px;

  .f-space {
    min-width: 70px;
    display: inline-block;
  }

  .f-space.zone {
    min-width: 45px;
  }

  .f-space1 {
    display: inline-block;
    font-weight: 500;
    padding-left: 5px;
  }
}
</style>