<template>
  <v-container class="custom-container">
    <div class="greetings my-5">
      <h2>Welcome</h2>
      <h4>{{ userName }}</h4>
    </div>
    <v-row class="information" justify="space-between">
      <v-col class="box-col" cols="12" xl="6" lg="6" md="6">
        <router-link :to="{ path: '/devices', query: { devicetype: 0 } }"
          ><div class="box">
            <div class="img"><img src="/img/devices.svg" alt="" /></div>
            <div class="info-details">
              <div class="heading">Total No. of Devices</div>
              <div class="count">{{ totalDevice }}</div>
            </div>
          </div></router-link
        >
      </v-col>
      <v-col class="box-col" cols="12" xl="6" lg="6" md="6">
        <router-link :to="{ path: '/devices', query: { devicetype: 0 } }"
          ><div class="box">
            <div class="img"><img src="/img/active.svg" alt="" /></div>
            <div class="info-details">
              <div class="heading">Active Devices</div>
              <div class="count">{{ activeDevice }}</div>
            </div>
          </div></router-link
        >
      </v-col>
      <v-col class="box-col" cols="12" xl="6" lg="6" md="6">
        <router-link to="/locations"
          ><div class="box">
            <div class="img"><img src="/img/locations.svg" alt="" /></div>
            <div class="info-details">
              <div class="heading">Locations</div>
              <div class="count">{{ locations }}</div>
            </div>
          </div></router-link
        >
      </v-col>
      <v-col class="box-col" cols="12" xl="6" lg="6" md="6">
        <router-link :to="{ path: '/devices', query: { devicetype: 1 } }"
          ><div class="box">
            <div class="img"><img src="/img/inactive.svg" alt="" /></div>
            <div class="info-details">
              <div class="heading">Inactive Devices</div>
              <div class="count">{{ inactiveDevices }}</div>
            </div>
          </div></router-link
        >
      </v-col>
      <v-col class="box-col" cols="12" xl="6" lg="6" md="6" v-if="access == 2">
        <router-link to="/staffs">
          <div class="box">
            <div class="img"><img src="/img/staffs.svg" alt="" /></div>
            <div class="info-details">
              <div class="heading">No. of Technicians</div>
              <div class="count">{{ staffs }}</div>
            </div>
          </div>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Dashboard",
  props: [
    "totalDevice",
    "locations",
    "activeDevice",
    "inactiveDevices",
    "staffs",
  ],
  computed: {
    userName() {
      var user = JSON.parse(localStorage.getItem("user"));
      var name = user.name;
      return name;
    },
    access() {
      var user = JSON.parse(localStorage.getItem("user"));
      var access_level = user.access_level;
      return access_level;
    },
  },
  data: () => ({}),
};
</script>
<style lang="scss" scoped>
.custom-container {
  height: 100%;
  max-width: 90%;
  margin: 15px auto;
  .greetings {
    padding-top: 50px;
  }
  .information {
    .box-col {
      flex: 0 0 48% !important;
      max-width: 48% !important;

      a {
        box-shadow: 0px 10px 30px #83838329;
        margin: 25px 0;
        padding: 25px;
        border-radius: 31px;
        min-height: 250px;
        align-items: center;
        justify-content: center;
        padding-left: 8%;
        display: flex !important;
        width: 100%;

        cursor: pointer;
        // transform: translateY(40px);
        animation: fade 1s ease-in-out;
        animation-delay: 0.2s;
      }
      .box {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        .img {
          width: 60px;
          height: 60px;
          margin-right: 40px;
          // border-radius: 50%;
          // background: #000;
          img {
            width: 100%;
            // padding: 13px;
          }
        }
        .info-details {
          .heading {
            font-size: 18px;
          }
          .count {
            font-size: 28px;
            font-weight: bold;
          }
        }
      }
    }
    @media screen and (min-width: 0) and (max-width: 700px) {
      .box-col {
        flex: 0 0 100% !important;
        max-width: 100% !important;
      }
    }

    @keyframes fade {
      0% {
        opacity: 0;
      }
      25% {
        opacity: 0.25;
      }
      50% {
        opacity: 0.5;
      }
      75% {
        opacity: 0.75;
      }
      100% {
        opacity: 1;
        // transform: translateY(0);
      }
    }
  }
}
</style>
