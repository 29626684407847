<template>
  <div class="device-info">
    <div class="feature-img">
      <div class="device-status">
        <v-row justify="space-between">
          <v-col
            ><div class="location-name">{{ name }}</div>
            <div class="no-of-device">{{ count }} Devices Currently Deployed</div>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="dev-tabs">
      <v-tabs>
        <v-tab>Active ({{info.length}})</v-tab>
        <v-tab>Previously Deployed ({{previous.length}})</v-tab>
        <v-tab-item>
          <div class="device-info-details">
            <div class="info-data" v-for="n in info" :key="n._id">
              <h3>{{ n.deviceName }}</h3>
              <h5>Device Id: {{ n.deviceNo }}</h5>
              <DeviceStatus :device="n" />
              <v-row justify="space-between my-3">
                <v-col cols="12" lg="6" xl="6" md="12">
                  <h5>Last pushed</h5>
                  <div class="time my-1" v-if="n.lastPushAt != null">
                    <v-icon color="#2CC9D6" class="mr-4"
                      >mdi-database-clock-outline</v-icon
                    >
                    {{
                      n.lastPushAt.toLocaleString("en-US", {
                        timeZone: "Asia/Kolkata",
                      }) | moment("from", "now")
                    }}
                    <span
                      ><strong>At: </strong>
                      {{
                        n.lastPushAt.toLocaleString("en-US", {
                          timeZone: "Asia/Kolkata",
                        }) | moment("MMMM Do YYYY,h:mm:ss A")
                      }}</span
                    >
                  </div>
                  <div class="time my-1" v-else>
                    <v-icon color="#2CC9D6" class="mr-4"
                      >mdi-database-clock-outline</v-icon
                    >
                    N/A
                  </div>
                </v-col>
                <v-col
                  class=""
                  align-self="end"
                  cols="12"
                  lg="6"
                  xl="6"
                  md="12"
                >
                  <h5>Log started at</h5>
                  <div class="time my-1" v-if="n.logStartedAt != null">
                    <v-icon color="#2CC9D6" class="mr-4"
                      >mdi-database-clock-outline</v-icon
                    >
                    {{
                      n.logStartedAt.toLocaleString("en-US", {
                        timeZone: "Asia/Kolkata",
                      }) | moment("from", "now")
                    }}
                    <span
                      ><strong>At: </strong>
                      {{
                        n.logStartedAt.toLocaleString("en-US", {
                          timeZone: "Asia/Kolkata",
                        }) | moment("MMMM Do YYYY,h:mm:ss A")
                      }}</span
                    >
                  </div>
                  <div class="time my-1" v-else>
                    <v-icon color="#2CC9D6" class="mr-4"
                      >mdi-database-clock-outline</v-icon
                    >
                    N/A
                  </div>
                </v-col>
              </v-row>
              <div class="view-more my-5">
                <router-link :to="'/devices/' + n.deviceNo"
                  ><CustomButton label="View Details"
                /></router-link>
              </div>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div class="device-info-details">
            <div class="info-data" v-for="n in previous" :key="n._id">
              <h3>{{ n.deviceName }}</h3>
              <h5>Device Id: {{ n.deviceNo }}</h5>
              <DeviceStatus :device="n" />
              <v-row justify="space-between my-3">
                <v-col cols="12" lg="6" xl="6" md="12">
                  <h5>Last pushed</h5>
                  <div class="time my-1" v-if="n.lastPushAt != null">
                    <v-icon color="#2CC9D6" class="mr-4"
                      >mdi-database-clock-outline</v-icon
                    >
                    {{
                      n.lastPushAt.toLocaleString("en-US", {
                        timeZone: "Asia/Kolkata",
                      }) | moment("from", "now")
                    }}
                    <span
                      ><strong>At: </strong>
                      {{
                        n.lastPushAt.toLocaleString("en-US", {
                          timeZone: "Asia/Kolkata",
                        }) | moment("MMMM Do YYYY,h:mm:ss A")
                      }}</span
                    >
                  </div>
                  <div class="time my-1" v-else>
                    <v-icon color="#2CC9D6" class="mr-4"
                      >mdi-database-clock-outline</v-icon
                    >
                    N/A
                  </div>
                </v-col>
                <v-col
                  class=""
                  align-self="end"
                  cols="12"
                  lg="6"
                  xl="6"
                  md="12"
                >
                  <h5>Log started at</h5>
                  <div class="time my-1" v-if="n.logStartedAt != null">
                    <v-icon color="#2CC9D6" class="mr-4"
                      >mdi-database-clock-outline</v-icon
                    >
                    {{
                      n.logStartedAt.toLocaleString("en-US", {
                        timeZone: "Asia/Kolkata",
                      }) | moment("from", "now")
                    }}
                    <span
                      ><strong>At: </strong>
                      {{
                        n.logStartedAt.toLocaleString("en-US", {
                          timeZone: "Asia/Kolkata",
                        }) | moment("MMMM Do YYYY,h:mm:ss A")
                      }}</span
                    >
                  </div>
                  <div class="time my-1" v-else>
                    <v-icon color="#2CC9D6" class="mr-4"
                      >mdi-database-clock-outline</v-icon
                    >
                    N/A
                  </div>
                </v-col>
              </v-row>
              <div class="view-more my-5">
                <router-link :to="'/devices/' + n.deviceNo"
                  ><CustomButton label="View Details"
                /></router-link>
              </div>
            </div>
          </div>
        </v-tab-item>
      </v-tabs>
    </div>

    <!-- <UnpairDialog /> -->
  </div>
</template>

<script>
import CustomButton from "../common/CustomButton.vue";
// import UnpairDialog from "../dialogs/UnpairDialog.vue";
import DeviceStatus from "../devices/DeviceStatus.vue";
export default {
  props: ["count", "name", "info", "previous"],
  components: {
    CustomButton,
    // UnpairDialog,
    DeviceStatus,
  },
  methods: {
    // unpair() {
    //   this.$store.commit("SET_UNPAIR_DIALOG", true);
    // },
  },
};
</script>

<style lang="scss" scoped>
.device-info {
  .feature-img {
    min-height: 350px;
    background: url(/img/praanbg.jpg), rgba(0, 0, 0, 0.6);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    display: flex;
    align-items: center;
    .device-status {
      max-width: 100%;
      flex: 0 0 100%;
      color: #fff;
      padding: 20px 4%;
      padding-left: 100px !important;
      .location-name {
        font-size: 26px;
        font-weight: bold;
        margin-bottom: 25px;
      }
      .device-active {
        font-size: 26px;
        font-weight: bold;
        span.active {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background: #00be16;
          display: inline-block;
          vertical-align: middle;
          margin: 15px;
        }
      }
      .device-inactive {
        font-size: 26px;
        font-weight: bold;
        span.inactive {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background: red;
          display: inline-block;
          vertical-align: middle;
          margin: 15px;
        }
      }
    }
  }
  .dev-tabs {
    position: relative;
    top: -40px;
    max-width: 90%;
    margin: auto;
  }
  .device-info-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    top: 40px;
    .info-data {
      background: #fff;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 10px;
      max-width: 48%;
      flex: 0 0 48%;
      padding: 50px;
      margin-bottom: 25px;
    }
    @media screen and (min-width: 0) and (max-width: 700px) {
      .info-data {
        flex: 0 0 100% !important;
        max-width: 100% !important;
      }
    }
  }
}
</style>