<template>
  <div>
    <v-dialog
      class="custom-dialog"
      transition="dialog-bottom-transition"
      max-width="450"
      v-model="this.$store.state.addDialog"
      @click:outside="close"
    >
      <div class="close text-right">
        <span @click="close"><img src="/img/x.svg" width="15" alt="" /></span>
      </div>
      <h3 class="text-center my-5">Add a new device</h3>
      <v-form ref="form">
        <v-text-field
          class="my-5 rounded-0"
          label="Device ID"
          type="text"
          color="#000000"
          ref="deviceid"
          :rules="deviceIdRules"
          v-model="form.deviceid"
          outlined
          required
        ></v-text-field>
        <v-text-field
          class="my-5 rounded-0"
          label="Device Name"
          type="text"
          color="#000000"
          ref="devicename"
          :rules="deviceNameRules"
          v-model="form.devicename"
          outlined
          required
        ></v-text-field>
        <v-text-field
          class="my-5 rounded-0"
          label="Zone Name"
          type="text"
          color="#000000"
          ref="devicename"
          :rules="zoneNameRules"
          v-model="form.zoneName"
          outlined
          required
        ></v-text-field>
        <!-- <v-select
        label="Client"
        outlined
        color="#000000"
        :items="items"
        item-text="name"
        ref="location"
        :rules="locationRules"
        item-value="_id"
        v-model="form.client"
        return-object
      >
      </v-select> -->
        <div class="text-center my-5">
          <span @click="confirmed"> <CustomButton label="Submit" /></span>
        </div>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import CustomButton from "../common/CustomButton.vue";
export default {
  components: {
    CustomButton,
  },
  data() {
    return {
      deviceIdRules: [(v) => !!v || "Device ID is required"],
      deviceNameRules: [(v) => !!v || "Device name is required"],
      zoneNameRules: [(v) => !!v || "Zone name is required"],
      form: {
        deviceid: "",
        devicename: "",
        zoneName: "",
      },
      client: null,
      locations: [],
      items: [],
      formHasErrors: false,
    };
  },
  mounted() {
    this.getLocations();
  },
  methods: {
    getLocations() {
      this.$axios
        .get("/admin/user/all")
        .then((res) => {
          this.locations = res.data.users;
          for (var i = 0; i <= res.data.users.length - 1; i++) {
            this.items.push({
              name: res.data.users[i].name,
              _id: res.data.users[i]._id,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    close() {
      this.$store.commit("SET_ADD_DIALOG", false);

      this.$refs.form.reset();
    },
    confirmed(e) {
      debugger;
      // const staff = localStorage.getItem("user");
      const client = { _id: this.$route.params.client };
      e.preventDefault();
      this.formHasErrors = false;

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true;
        this.$refs.form.validate();
      });

      if (this.formHasErrors == false) {
        this.$axios
          .post("/admin/device/add", {
            deviceName: this.form.devicename,
            deviceNo: this.form.deviceid,
            zoneName: this.form.zoneName,
            activated: false,
            logStatus: false,
            client: client,
          })
          .then((res) => {
            console.log(res);
            if (res.status == 200) {
              this.$refs.form.reset();
              this.$store.commit("SET_ADD_DIALOG", false);
              this.$store.commit("SET_SNACKBAR", {
                active: true,
                message: "Device added successfully",
              });
              this.$emit("device_added");
            }
          });
      }
    },
  },
};
</script>
<style lang="scss">
.v-dialog {
  background: #fff;
  padding: 25px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  .close {
    span {
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss" scoped>
.custom-dialog {
}
</style>