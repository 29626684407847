<template>
  <div>
    <div class="qr-scanner">
      <div class="go-back">
        <span @click="$router.go(-1)"
          ><v-icon class="mr-2" color="#000">mdi-chevron-left</v-icon>Go
          back</span
        >
      </div>
      <div class="scan-heading">
        <h2>Scan your device QR code</h2>
      </div>
      <StreamBarcodeReader
        ref="stream"
        v-if="isMounted"
        @decode="onDecode"
        @loaded="onLoaded"
      >
      </StreamBarcodeReader>
      <div class="not-working text-center">
        <h2>Not working ?</h2>
        <v-text-field
          class="my-5"
          label="Enter Device ID"
          type="text"
          color="#000000"
          ref="deviceid"
          v-model="form.deviceid"
          outlined
          required
        ></v-text-field>
        <span @click="search" class="text-decoration-underline">SUBMIT</span>
      </div>
    </div>
    <!-- <QrcodeStream />
    <QrcodeCapture /> -->
  </div>
</template>

<script>
// import { QrcodeStream, QrcodeCapture } from "vue-qrcode-reader";
import { StreamBarcodeReader } from "vue-barcode-reader";
export default {
  components: {
    StreamBarcodeReader,
    // QrcodeStream,
    // QrcodeCapture,
  },
  data() {
    return {
      form: {
        deviceid: "",
      },
      isMounted: false,
      deviceInfo: null,
    };
  },
  mounted() {
    this.isMounted = true;
    this.$ref.stream.codeReader.reset();
  },
  unmounted() {
    this.isMounted = false;
  },
  methods: {
    onDecode(result) {
      console.log("decoded", result);
      this.$axios
        .get("/device?deviceNo=" + result)
        .then((res) => {
          this.deviceInfo = res.data.device;
          if (this.deviceInfo != null) {
            this.$router.push("/devices/" + result);
          } else {
            this.$store.commit("SET_SNACKBAR", {
              active: true,
              message: "Device Not Found",
              color: "error",
            });
            const staff = localStorage.getItem("user");
            this.$axios
              .post("/admin/device/add", {
                deviceName: "Device " + result,
                deviceNo: result,
                currentLocation: null,
                locationHistory: [],
                activated: false,
                logStatus: false,
                deviceHandler: JSON.parse(staff),
              })
              .then((res) => {
                console.log(res);
                if (res.status == 200) {
                  this.$store.commit("SET_SNACKBAR", {
                    active: true,
                    message: "New device added successfully",
                  });
                  this.$router.push("/devices/" + res.data.deviceId);
                }
              });
          }
        })
        .catch((err) => console.log(err));
    },
    onLoader() {
      console.log("loaded");
    },
    search() {
      this.$router.push("/devices/" + this.form.deviceid);
    },
  },
};
</script>
<style >
/* .qrcode-stream__camera,
.qrcode-stream__pause-frame {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  background-size: cover;
  max-width: inherit !important;
  max-height: inherit !important;
}

.qrcode-stream__inner-wrapper {
  position: inherit !important;
  max-width: inherit !important;
  max-height: inherit !important;
  z-index: inherit !important;
} */
</style>
<style lang="scss">
.qr-scanner {
  .scanner-container {
    // position: absolute !important;
    // top: 0 !important;
    // bottom: 0 !important;
    // width: 100% !important;
    // height: 100% !important;
    // overflow: hidden !important;
    div {
      // position: absolute !important;
      // top: 0 !important;
      // bottom: 0 !important;
      // width: 100% !important;
      // height: 100% !important;
      // overflow: hidden !important;
    }
  }
  .laser {
    width: 40% !important;
    display: none !important;
    margin-left: 30% !important;
  }
  .overlay-element {
    // background: rgba($color: #000000, $alpha: 0.8) !important;
    // clip-path: polygon(
    //   0% 0%,
    //   0% 100%,
    //   25% 100%,
    //   25% 30%,
    //   75% 30%,
    //   75% 70%,
    //   25% 70%,
    //   25% 100%,
    //   100% 100%,
    //   100% 0%
    // ) !important;
    // clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%) !important;
    // clip-path: inset(0 0, 100% 0%, 100% 100%, 0% 100%) !important;
  }
  video {
    // width: auto !important;
    // height: 100vh !important;
    // max-width: unset !important;
    // max-height: 100% !important;
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100vh;
    max-width: unset !important;
    max-height: unset !important;
    width: auto;
    height: auto;
    z-index: 0;
    transform: translateX(-50%) translateY(-50%);
    background: url("//demosthenes.info/assets/images/polina.jpg") no-repeat;
    background-size: cover;
    transition: 1s opacity;

    /* Make video to at least 100% wide and tall */
    // min-width: 100%;
    // min-height: 100%;

    // /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    // width: auto;
    // height: auto;

    // /* Center the video */
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
  }
}
</style>
<style lang="scss" scoped>
.qr-scanner {
  width: 100%;
  // max-width: 1024px;
  min-height: 100vh;
  margin: auto;
  position: relative;
  .go-back {
    width: 100%;
    position: absolute;
    z-index: 100;
    color: #000;
    background: #fff;
    top: 0;
    left: 0;
    right: 0;
    padding: 15px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
  }
  .not-working {
    width: 500px;
    // max-width: 500px !important;
    // width: 100%;
    bottom: 4%;
    padding: 25px;
    // left: 40%;
    position: absolute;
    background: #fff;
    // height: 200px;
    border-radius: 10px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (min-width: 0) and (max-width: 560px) {
    .not-working {
      width: 90%;
    }
  }
  .scan-heading {
    position: absolute;
    text-align: center;
    z-index: 10;
    color: #fff;
    width: 350px;
    top: 10%;
    // left: 43%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>