<template>
  <div>
    <v-dialog
      class="custom-dialog"
      transition="dialog-bottom-transition"
      max-width="450"
      v-model="this.$store.state.editDeviceDialog"
      @click:outside="close"
    >
      <div class="close text-right">
        <span @click="close"><img src="/img/x.svg" width="15" alt="" /></span>
      </div>
      <h3 class="text-center my-5">Edit details for this device</h3>
      <v-text-field
        class="my-5 rounded-0"
        label="Enter name"
        type="text"
        color="#000000"
        ref="location"
        v-model="form.name"
        outlined
        required
      ></v-text-field>
      <v-text-field
        class="my-5 rounded-0"
        label="Enter Device ID"
        type="text"
        color="#000000"
        ref="location"
        v-model="form.deviceId"
        outlined
        required
      ></v-text-field>
      <v-text-field
        class="my-5 rounded-0"
        label="Zone name"
        type="text"
        color="#000000"
        v-model="form.zoneName"
        outlined
        required
      ></v-text-field>
      <div class="text-center my-5">
        <span @click="confirmed"
          ><CustomButton label="Update" :white="true"
        /></span>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import CustomButton from "../common/CustomButton.vue";
export default {
  props: ["name", "deviceNo", "zoneName"],
  components: {
    CustomButton,
  },
  data() {
    return {
      form: {
        name: "",
        deviceId: "",
        zoneName: "",
      },
    };
  },
  mounted() {
    this.form.name = this.name;
    this.form.deviceId = this.deviceNo;
    this.form.zoneName = this.zoneName;
  },
  methods: {
    close() {
      this.$store.commit("SET_EDIT_DEVICE_DIALOG", false);
    },
    confirmed() {
      if (this.deviceNo != this.form.deviceId) {
        this.$axios
          .put("/device/admin/", {
            deviceNo: this.$route.params.id,
            name: this.form.name,
            newNo: this.form.deviceId,
            zoneName: this.form.zoneName,
          })
          .then((res) => {
            if (res.status == 200) {
              this.$emit("name_updated", res.data.deviceId);
              this.close();
            }
          })
          .catch((err) => console.log(err));
      } else {
        this.$axios
          .put("/device/admin/", {
            deviceNo: this.$route.params.id,
            name: this.form.name,
            zoneName: this.form.zoneName,
          })
          .then((res) => {
            if (res.status == 200) {
              this.$emit("name_updated", null);
              this.close();
            }
          })
          .catch((err) => console.log(err));
      }
    },
  },
};
</script>
<style lang="scss">
.v-dialog {
  background: #fff;
  padding: 25px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  .close {
    span {
      cursor: pointer !important;
    }
  }
}
</style>
<style lang="scss" >
.custom-dialog {
}
</style>