<template>
  <div class="device-card">
    <v-row align="center">
      <v-col>
        <h2>{{ data.locationName }}</h2>
        <!-- <div class="devices" v-if="data.currentDeployedDevices.length != null">
          {{ data.currentDeployedDevices.length }}
          Devices Currently Deployed
        </div>
        <div class="devices" v-else>0 Devices</div> -->
      </v-col>
      <v-col class="text-center">
        <router-link :to="'/locations/' + data._id">
          <CustomButton :white="true" label="View Devices"
        /></router-link>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CustomButton from "../common/CustomButton.vue";
export default {
  props: ["data"],
  components: {
    CustomButton,
  },
};
</script>

<style lang="scss" scoped>
.device-card {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  padding: 50px;
  margin: 20px auto;
  max-width: 90%;
  opacity: 0;
  transform: translateY(40px);
  animation: fade 0.5s forwards;
  animation-delay: 0.2s;
}
@keyframes fade {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>