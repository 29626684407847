<template>
  <div>
    <v-dialog
      class="custom-dialog"
      transition="dialog-bottom-transition"
      max-width="450"
      v-model="this.$store.state.alertDialog.status"
      @click:outside="close"
    >
      <div class="close text-right">
        <span @click="close"><img src="/img/x.svg" width="15" alt="" /></span>
      </div>
      <h3 class="text-center my-5">
        {{ this.$store.state.alertDialog.message }}
      </h3>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "AlertDialog",
  methods: {
    close() {
      this.$store.commit("SET_ALERT_DIALOG", { status: false, message: "" });
    },
  },
};
</script>
<style lang="scss">
.v-dialog {
  background: #fff;
  padding: 25px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  .close {
    span {
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss" scoped>
.custom-dialog {
}
</style>