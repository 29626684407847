<template>
  <div class="device-info">
    <div class="device-img text-center">
      <GoBack />
      <div class="img">
        <div class="signal text-right">
          <SignalBar :network="data.networkStrength" />
        </div>
        <img src="/img/mk/ses.png" alt="" v-if="data.deviceName == 'SES_105370'"/>
        <img src="/img/mk/mk2.png" alt="" v-else/>
      </div>
      <div class="device-name">
        {{ data.deviceName }}
        <span v-if="access == 2" class="edit-icon" @click="editName"
          ><v-icon color="#000">mdi-square-edit-outline</v-icon></span
        >
        <span class="zone-name d-block body-2 mt-5">
          <span class="edit-icon">{{ data.zoneName }}</span>
        </span>
      </div>

      <div class="links">
        <router-link
          :to="'/devices/' + $route.params.client + '/data/' + $route.params.id"
          class="link"
        >
          Air Quality Index
        </router-link>
        <router-link
          class="link"
          :to="
            '/devices/' + $route.params.client + '/controls/' + $route.params.id
          "
        >
          Device Controls
          <div
            style="display: inline-block"
            class="ml-1"
            v-if="data.health != 0 || data.controlError != 0"
          >
            <v-icon color="red">mdi-alert-circle-outline</v-icon>
          </div>
        </router-link>
      </div>
    </div>
    <div class="device-stats">
      <div class="add-on text-right">
        <span class="setting" @click="openAdditonalControls"
          ><v-icon class="mr-2" color="#000" style="margin-right: 0 !important"
            >mdi-tools</v-icon
          ></span
        >
      </div>
      <div class="controls">
        <v-row class="control-row">
          <v-col cols="6" class="col-control">
            <div class="control-head">Device Power</div>
            <v-row align="center">
              <v-col cols="4"
                ><img src="/img/mk/mkonep.png" width="100%" alt=""
              /></v-col>
              <v-col>
                <span class="control-sub"
                  >Power Status : <span v-if="masterControl">On</span
                  ><span v-else>Off</span></span
                >
                <br />
                <div class="switch-toggle switch-3 switch-candy">
                  <input
                    id="poweron"
                    name="power"
                    type="radio"
                    value="true"
                    @change="confirmPower($event)"
                    v-model="masterControl"
                  />
                  <label for="poweron" onclick="">On</label>

                  <input
                    id="poweroff"
                    name="power"
                    type="radio"
                    value="false"
                    @change="confirmPower($event)"
                    v-model="masterControl"
                  />
                  <label for="poweroff" onclick="">Off</label>
                  <!-- 
                  <input id="powerauto" name="power" type="radio" />
                  <label for="powerauto" onclick="">Auto</label> -->
                </div>
                <br />
                <br />
                <br />
                <p class="control-desc">
                  The device automatically shuts off power when not needed. This
                  switches off all functions of the device.
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="col-control">
            <div class="control-head">Lid Control</div>
            <v-row align="center">
              <v-col cols="4"
                ><img src="/img/mk/lidcontrol.png" width="100%" alt=""
              /></v-col>
              <v-col>
                <span class="control-sub"
                  >Lid Status : <span v-if="data.lidOpen">Open</span
                  ><span v-else>Closed</span></span
                >
                <br />
                <div class="switch-toggle switch-3 switch-candy">
                  <input
                    id="on"
                    name="lid"
                    type="radio"
                    value="true"
                    @change="confirmLid($event)"
                    v-model="data.lidOpen"
                  />
                  <label for="on" onclick="">Open</label>

                  <input
                    id="na"
                    name="lid"
                    type="radio"
                    value="false"
                    @change="confirmLid($event)"
                    v-model="data.lidOpen"
                  />
                  <label for="na" class="disabled" onclick="">Close</label>

                  <!-- <input id="off" name="lid" type="radio" />
                  <label for="off" onclick="">Auto</label> -->
                </div>
                <br />
                <br />
                <br />
                <p class="control-desc">
                  The device automatically shuts the lid off about 20 minutes
                  before it’s about to rain or snow, stopping the air
                  purification capacity of the device. When the lid is off, the
                  device sensors still work, but purification doesn’t.
                </p>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="pa-0"
            ><v-divider style="background-color: #9e9e9e"></v-divider
          ></v-col>
          <v-col cols="6" class="col-control">
            <div class="control-head">Device Lighting</div>
            <v-row align="center">
              <v-col cols="4">
                <img src="/img/mk/led.png" width="100%" alt="" />
              </v-col>
              <v-col class="col-control">
                <!-- <span class="control-sub">Power Status : On</span> -->

                <!-- <span>Status : On</span> -->
                <div class="switch-div">
                  <span class="led"
                    >Status :
                    <v-switch
                      color="#000000"
                      class="d-inline-block justify-left"
                      v-model="switch1"
                      @change="ledChange"
                      inset
                      :label="`${ledStatus.toString()}`"
                    ></v-switch>
                  </span>
                </div>
                <div
                  class="led-div"
                  v-if="data.led != '#______' || switch1 == true"
                >
                  <span class="led"
                    >Colour :
                    <v-input-colorpicker
                      class="led-input"
                      v-model="data.led"
                      @change="confirmLed"
                    />
                    <!-- <input
                      type="color"
                      id="ledcolor"
                      name="ledcolor"
                      v-model="data.ledColor"
                  /> -->
                  </span>
                  <span class="ml-5">{{ data.led }}</span>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="col-control">
            <div class="control-head">Collection Chamber</div>
            <v-row align="center">
              <v-col cols="4"
                ><img src="/img/mk/cc.png" width="100%" alt=""
              /></v-col>
              <v-col>
                <!-- <span class="control-sub">Power Status : On</span> -->
                <p class="control-desc">
                  Collection chamber sensor not available.
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider style="background-color: #9e9e9e"></v-divider>
        <v-col cols="6"> </v-col>
      </div>
    </div>
    <AdditonalControlDialog
      :pub="data.pub"
      :fan="data.fan"
      :ion="data.power"
      @confirmed="changeAdditional"
    />
    <EditDeviceDialog
      :name="data.deviceName"
      :deviceNo="data.deviceNo"
      :zoneName="data.zoneName"
      @name_updated="nameUpdate"
    />
    <ConfirmDialog
      message="Please confirm to update "
      @notconfirmed="notconfirm"
      @confirmed="changeControls"
    />
  </div>
</template>

<script>
// import CustomButton from "../common/CustomButton.vue";
// import UnpairDialog from "../dialogs/UnpairDialog.vue";
// import PairDialog from "../dialogs/PairDialog.vue";
import ConfirmDialog from "../dialogs/ConfirmDialog.vue";
// import DeviceStatusTransparent from "./DeviceStatusTransparent.vue";
import EditDeviceDialog from "../dialogs/EditDeviceName.vue";
import GoBack from "../common/GoBack.vue";
import SignalBar from "./SignalBar.vue";
import AdditonalControlDialog from "../dialogs/AdditionalControlDialog.vue";
// import ApexCharts from "apexcharts";
export default {
  props: ["data"],
  components: {
    // CustomButton,
    // UnpairDialog,
    // PairDialog,
    ConfirmDialog,
    // DeviceStatusTransparent,
    EditDeviceDialog,
    GoBack,
    SignalBar,
    AdditonalControlDialog,
  },
  data() {
    return {
      masterControl: true,
      switch1: false,
      ledStatus: "OFF",
      additional: false,
      network: 0,
      aqiShow: true,
      ledColor: "#3194C9",
      row: "radio-2",
      masterToggle: false,
      master: {
        lid: 0,
        ion: 0,
        led: "#______",
        fan: "0",
      },
      form: {
        offset: "",
      },
      lid: 0,
      power: 0,
      items: [
        { text: "5 minutes", value: 5 },
        { text: "10 minutes", value: 10 },
        { text: "15 minutes", value: 15 },
        { text: "30 minutes", value: 30 },
        { text: "1 hour", value: 60 },
      ],
    };
  },
  computed: {
    access() {
      let user = JSON.parse(localStorage.getItem("user"));
      return user.access_level;
    },
  },
  watched: {},
  mounted() {
    // console.log("ROute:", this.$route);
    if (this.data.led != "#______") {
      this.switch1 = true;
      this.ledStatus = "ON";
    }
    if (
      this.data.led != "#______" ||
      this.data.lidOpen != false ||
      this.data.power != false ||
      this.data.fan != "0"
    ) {
      this.masterControl = true;
    } else {
      this.masterControl = false;
    }
  },
  methods: {
    changeAdditional(form) {
      // console.log("from additional form:", form.fan, form.pub);
      this.data.pub = form.pub;
      this.data.fan = form.fan;
      this.data.power = form.ion.toString();
      console.log(form.ion, typeof form.ion);
      this.changeControls();
    },
    ledChange() {
      if (this.switch1 == true) {
        this.ledStatus = "ON";
        this.data.led = "#FFFFFF";
        this.confirmLed();
      } else {
        this.ledStatus = "OFF";
        this.data.led = "#______";
        this.confirmLed();
      }
    },
    openAdditonalControls() {
      this.$store.commit("SET_ADDITIONAL_CONTROL_DIALOG", true);
    },
    // closeAdditonalControls() {
    //   this.additional = false;
    // },
    confirmPower(event) {
      this.masterToggle = true;
      var data = event.target.value;

      // console.log(data, typeof data);
      if (data === "true") {
        this.master.led = "#FFFFFF";
        this.master.ion = 1;
        this.master.lid = 1;
        this.master.fan = "25";
      } else {
        this.master.led = "#______";
        this.master.ion = 0;
        this.master.lid = 0;
        this.master.fan = "0";
      }
      this.$store.commit("SET_CONFIRM_DIALOG", true);
    },
    confirmLed() {
      this.$store.commit("SET_CONFIRM_DIALOG", true);
    },
    confirmLid(event) {
      var data = event.target.value;
      console.log(data);
      this.$store.commit("SET_CONFIRM_DIALOG", true);
    },
    // confirmPub() {
    //   this.$store.commit("SET_CONFIRM_DIALOG", true);
    // },
    // confirmfan() {
    //   this.$store.commit("SET_CONFIRM_DIALOG", true);
    // },
    notconfirm() {
      this.masterToggle = false;
      this.$emit("data_changed");
      setTimeout(() => {
        if (this.data.led != "#______") {
          this.switch1 = true;
          this.ledStatus = "ON";
        } else {
          this.switch1 = false;
          this.ledStatus = "OFF";
        }
        if (
          this.data.led != "#______" &&
          this.data.lidOpen != false &&
          this.data.power != false &&
          this.data.fan != "0"
        ) {
          this.masterControl = true;
        } else {
          this.masterControl = false;
        }
      }, 500);
    },
    changeControls() {
      if (this.data.lidOpen === "true" || this.data.lidOpen === true) {
        this.lid = 1;
      } else {
        this.lid = 0;
      }
      if (this.data.power === "true" || this.data.power === true) {
        this.power = 1;
      } else {
        this.power = 0;
      }
      if (this.masterToggle) {
        this.$axios
          .post("/device/control?deviceId=" + this.data.deviceNo, {
            lid: this.master.lid,
            ion: this.master.ion,
            frequency: this.data.pub,
            fan: this.master.fan.toString(),
            led: this.master.led.toString(),
          })
          .then((res) => {
            console.log(res);
            this.$emit("data_changed");
          })
          .catch((err) => console.log(err));
      } else {
        this.$axios
          .post("/device/control?deviceId=" + this.data.deviceNo, {
            lid: this.lid,
            ion: this.power,
            frequency: this.data.pub,
            fan: this.data.fan.toString(),
            led: this.data.led.toString(),
          })
          .then((res) => {
            console.log(res);
            this.$emit("data_changed");
          })
          .catch((err) => console.log(err));
      }
    },
    getNetwork() {
      this.$axios
        .get("/device/sensordata")
        .then((res) => {
          this.network = parseInt(res.data.values[0].data.csq);
          console.log("Network Status ", this.network);
        })
        .catch((err) => console.log(err));
    },
    editName() {
      this.$store.commit("SET_EDIT_DEVICE_DIALOG", true);
    },
    nameUpdate(deviceId) {
      this.$store.commit("SET_SNACKBAR", {
        active: true,
        message: "Device name updated successfully",
      });
      if (deviceId != null) {
        // console.log("device:", deviceId);
        this.$router.replace({ params: { id: deviceId } });
      }
      this.$emit("data_changed");
    },
  },
};
</script>
<style lang="scss">
// .led-path {
//   fill: red !important;
// }

.v-application--is-ltr .v-input--selection-controls__input {
  margin-left: 12px !important;
}
.led-input {
  -webkit-appearance: none;
  padding: 0;
  border: none;
  border-radius: 30px;
  width: 60px;
  height: 30px;
  position: relative;
  top: 5px;
  left: 9px;
  border: 1px solid #cccccc !important;
}
#ledcolor::-webkit-color-swatch {
  border: none;
  border-radius: 30px;
  padding: 0;
}
#ledcolor::-webkit-color-swatch-wrapper {
  border: none;
  border-radius: 30px;
  padding: 0;
}
.qr-img {
  img {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
</style>
<style lang="scss" scoped>
// #chart-div {
//   width: 80% !important;
//   margin-top: 50px;
//   margin-left: 40px;
//   margin-right: auto;
// }
.device-info {
  display: flex;
  .device-aqi {
    width: 100%;
  }
  .device-stats {
    // width: 100%;
    flex-grow: 1;
    margin-left: 25px;
    .add-on {
      padding: 60px 60px 0 0;
      .setting {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        box-shadow: 0px 3px 6px #00000029;
        cursor: pointer;
        padding: 15px;
      }
    }
    .controls {
      padding: 25px 25px;
      .control-row {
        min-height: 85vh;
        align-content: space-evenly;
        .col-control {
          min-height: 90px;
        }
      }
      .control-head {
        font-size: 22px;
        padding-bottom: 25px;
        font-weight: 500;
      }
      .control-sub {
        font-size: 20px;
        font-weight: 500;
      }
      .control-desc {
        color: #00000090;
        // margin-top: 20px;
        // padding-top: 20px;
        margin-right: 60px;
        font-size: 14px;
        // margin: 40px;
      }
      .switch-toggle {
        float: left;
        background: #fff;
        max-width: 250px;
        margin-top: 20px;
        border-radius: 20px;
        border: 0.5px solid #ccc;
      }
      .switch-toggle input {
        position: absolute;
        opacity: 0;
      }
      .switch-toggle input + label {
        padding: 7px;
        float: left;
        color: #000;
        width: 70px;
        text-align: center;
        cursor: pointer;
        border-radius: 20px;
      }
      .switch-toggle input:checked + label {
        background: #000;
        color: #fff;
      }
      .switches.sm {
        max-width: 100px;
      }
      .switches {
        max-width: 200px;
        margin-top: 20px;
        border-radius: 20px;
        border: 0.5px solid #ccc;
        display: flex;
        justify-content: space-between;
        span {
          padding: 5px 15px;
          cursor: pointer;
        }
        span.active {
          background: #000;
          color: #fff;
          border-radius: 20px;
        }
      }
      .switch-div {
        width: 100%;
        display: block;
      }
      .led-div {
        width: 100%;
        display: inline-block;
      }
      .led {
        margin-top: 0px;
        display: inline-block;
      }
    }
  }
  .device-img {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 45px;
    min-height: 100vh;
    background: rgb(240, 240, 240) !important;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    .img {
      img {
        max-width: 60%;
      }
    }
    .device-name {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 40px;
    }
    .edit-icon {
      background: rgb(210, 210, 210) !important;
      padding: 5px;
      margin-left: 10px;
      border-radius: 2px;
      cursor: pointer;
    }
    .links {
      margin-top: 10px;
      a.link {
        min-width: 140px;
        background: #fff;
        margin-bottom: 20px;
        display: block !important;
        padding: 15px;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.4s ease-in-out;
        &:hover {
          background: #000;
          color: #fff !important;
        }
      }
      .link.router-link-exact-active {
        background: #000;
        color: #fff !important;
      }
    }
  }

  .device-info-details {
    position: relative;
    top: -40px;
    .info-data {
      background: #fff;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 10px;
      max-width: 90%;
      margin: auto;
      padding: 50px;
      margin-bottom: 25px;
    }
    .hide {
      display: none;
    }
  }
}
</style>