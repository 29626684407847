<template>
  <div class="device-card text-center">
    <div
      class="ml-5 error-icon"
      v-if="data.health != 0 || data.controlError != 0"
    >
      <v-icon color="red">mdi-alert-circle-outline</v-icon>
    </div>
    <img src="/img/mk/lid.png" width="150" alt="" />
    <h2>{{ data.deviceName }}</h2>
    <v-row class="my-5">
      <v-col cols="6">
        <div class="text-decoration-underline">Device ID</div>
        <div class="font-weight-bold mt-1">
          {{ data.deviceNo }}
        </div>
      </v-col>
      <v-col cols="6"
        ><div class="text-decoration-underline">Zone</div>
        <div class="font-weight-bold mt-1">
          {{ data.zoneName }}
        </div></v-col
      >
    </v-row>
    <span class="ma-5">
      <router-link
        :to="'/devices/' + data.client._id + '/data/' + data.deviceNo"
      >
        <CustomButton :white="true" label="View Details" /> </router-link
    ></span>
    <!-- <span class="ma-5"
      ><span @click="confirm">
        <CustomButton :red="true" label="Remove Device" /> </span
    ></span> -->
    <ConfirmDialog
      message="Please make sure to download the previous data before removing the device"
      @confirmed="deleteDevice"
    />
  </div>
</template>

<script>
import CustomButton from "../common/CustomButton.vue";
// import DeviceStatus from "./DeviceStatus.vue";
import ConfirmDialog from "../dialogs/ConfirmDialog.vue";
// import SignalBar from "./SignalBar.vue";
export default {
  props: ["data", "access"],
  components: {
    CustomButton,
    // DeviceStatus,
    ConfirmDialog,
    // SignalBar
  },
  methods: {
    confirm() {
      this.$store.commit("SET_CONFIRM_DIALOG", true);
    },
    deleteDevice() {
      this.$axios
        .delete("/device", {
          data: {
            deviceNo: this.data.deviceNo,
          },
        })
        .then((res) => {
          console.log(res);
          this.$store.commit("SET_SNACKBAR", {
            active: true,
            message: "Device Deleted Successfully",
          });
          this.$emit("device_removed");
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style lang="scss" scoped>
.device-card {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(204, 204, 204, 1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(204, 204, 204, 1);
  box-shadow: 0px 0px 10px 0px rgba(204, 204, 204, 1);
  border-radius: 10px;
  padding: 50px;
  margin: 20px 0px;
  width: 100%;
  position: relative;
}
.error-icon {
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>