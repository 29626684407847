<template>
  <div class="client-card">
    <div class="client-logo my-5">
      <img
        v-if="!client.logo"
        alt="Avatar"
        src="https://mkone.praan.io/img/praan-sq.png"
      />
      <img v-else :alt="client.name" :src="this.$serverUrl + client.logo" />
    </div>
    <div class="client-name font-weight-bold my-5">{{ client.name }}</div>
    <v-row class="my-5">
      <v-col cols="6">
        <div class="text-decoration-underline">Client Status</div>
        <div class="font-weight-bold">
          <v-switch
            class="v-input--reverse"
            v-model="client.authorized"
            :disabled="disabled"
            @change="authorizeClient(client._id, client.authorized)"
            label="Active"
          ></v-switch>
        </div>
      </v-col>
      <v-col cols="6"
        ><div class="text-decoration-underline">No. of Devices</div>
        <div class="font-weight-bold number mt-5">{{ devices }}</div></v-col
      >
    </v-row>
    <div class="my-5">
      <router-link :to="'/devices/' + client._id"
        ><CustomButton label="View Details"
      /></router-link>
    </div>
    <v-row class="caption">
      <v-col class="text-right"
        ><div class="text-decoration-underline">
          <span @click="manage(client)">Manage</span>
        </div></v-col
      >
      <v-col class="text-left"
        ><div class="text-decoration-underline red-text">
          <span @click="removeClient">Remove</span>
        </div></v-col
      >
    </v-row>
    <EditUserDialog @password_reset="updateClient" />
  </div>
</template>

<script>
import CustomButton from "../common/CustomButton.vue";
import EditUserDialog from "../dialogs/EditUserDialog.vue";
export default {
  props: ["client"],
  components: {
    CustomButton,
    EditUserDialog,
  },
  data() {
    return {
      devices: 0,
    };
  },
  mounted() {
    this.getCountofDevices();
  },
  methods: {
    updateClient() {
      this.$emit("updatePage");
      this.$store.commit("SET_SNACKBAR", {
        active: true,
        message: "Client updated successfully",
      });
    },
    getCountofDevices() {
      this.$axios
        .get("device/admin/all?clientid=" + this.client._id)
        .then((res) => {
          this.devices = res.data.devices.length;
        })
        .catch((err) => console.log(err));
    },
    manage(item) {
      this.$store.commit("SET_EDIT_USER_DIALOG", {
        status: true,
        user: item,
        type: "staff",
      });
    },
    authorizeClient(id, authorized) {
      this.$axios
        .put("/admin/user/", {
          id: id,
          authorized: authorized,
        })
        .then((res) => {
          console.log(res);
          this.$store.commit("SET_SNACKBAR", {
            active: true,
            message: "Client Updated Successfully",
          });
          this.$emit("data_changed");
        })
        .catch((err) => console.log(err));
    },
    removeClient() {
      this.$emit("remove", this.client.email);
    },
  },
};
</script>
<style lang="scss">
.v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: center;
  .v-application--is-ltr & {
    .v-input--selection-controls__input {
      margin-right: 0;
      margin-left: 8px;
    }
  }
  .v-application--is-rtl & {
    .v-input--selection-controls__input {
      margin-left: 0;
      margin-right: 8px;
    }
  }
}
.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  flex: unset !important;
}
.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 5px;
  margin-left: 52px;
}
</style>
<style lang="scss" scoped>
.client-card {
  text-align: center;
  padding: 40px;
  .client-logo {
    img {
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 50%;
      // padding: 2px;
      width: 90px;
      height: 90px;
    }
  }
  span {
    cursor: pointer;
  }
  .red-text {
    color: #ef5350;
  }
}
</style>