<template>
  <div class="pair-device">
    <h2>Pair a new device</h2>
    <v-spacer />
    <v-form ref="form">
      <v-text-field
        class="my-5 rounded-0"
        label="Device ID"
        type="text"
        color="#000000"
        ref="deviceid"
        :rules="deviceIdRules"
        v-model="form.deviceid"
        outlined
        required
      ></v-text-field>
      <v-text-field
        class="my-5 rounded-0"
        label="Device Name"
        type="text"
        color="#000000"
        ref="devicename"
        :rules="deviceNameRules"
        v-model="form.devicename"
        outlined
        required
      ></v-text-field>
      <v-select
        label="Device location(optional)"
        outlined
        color="#000000"
        :items="items"
        item-text="locationName"
        ref="location"
        :rules="locationOptional ? [] : locationRules"
        item-value="_id"
        v-model="location"
        return-object
      >
      </v-select>
      <div class="text-center my-5">
        <span @click="submit"> <CustomButton label="Submit" /></span>
      </div>
    </v-form>
    <!-- <SnackBar /> -->
  </div>
</template>

<script>
import CustomButton from "../common/CustomButton.vue";
// import SnackBar from "../common/SnackBar.vue";
export default {
  name: "Search",
  components: {
    CustomButton,
    // SnackBar,
  },
  data() {
    return {
      deviceIdRules: [(v) => !!v || "Device ID is required"],
      deviceNameRules: [(v) => !!v || "Device name is required"],
      locationRules: [(v) => !!v || "Location is required"],
      locationOptional: true,
      form: {
        deviceid: "",
        devicename: "",
      },
      location: null,
      locations: [],
      items: [],
      formHasErrors: false,
    };
  },
  mounted() {
    this.getLocations();
  },
  methods: {
    getLocations() {
      this.$axios
        .get("/admin/location/all")
        .then((res) => {
          this.locations = res.data.locations;
          for (var i = 0; i <= res.data.locations.length - 1; i++) {
            this.items.push({
              locationName: res.data.locations[i].locationName,
              _id: res.data.locations[i]._id,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    async submit(e) {
      e.preventDefault();
      this.formHasErrors = false;

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true;
        this.$refs["deviceid"].validate(true);
        this.$refs["devicename"].validate(true);

        // console.log(this.form[f]);
      });

      if (this.formHasErrors == false) {
        const staff = localStorage.getItem("user");
        let history = [];
        if (this.location != null) {
          history.push(this.location);
        }
        this.$axios
          .post("/admin/device/add", {
            deviceName: this.form.devicename,
            deviceNo: this.form.deviceid,
            currentLocation: this.location,
            locationHistory: history,
            activated: false,
            logStatus: false,
            deviceHandler: JSON.parse(staff),
          })
          .then((res) => {
            console.log(res);
            if (res.status == 200) {
              this.$store.commit("SET_SNACKBAR", {
                active: true,
                message: "Device added successfully",
              });
              this.$refs.form.reset();
              this.$router.push("/devices/" + res.data.deviceId);
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pair-device {
  max-width: 550px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 21px;
  padding: 50px;
  margin-top: 120px !important;
  margin: auto;
}
</style>