<template>
  <div class="signal">
    <div class="signal-icon" :class="signal">
      <div class="signal-bar"></div>
      <div class="signal-bar"></div>
      <div class="signal-bar"></div>
    </div>
    <div style="font-size: 8px; font-weight: 400; margin-left: -3px">
      {{ signal }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["network"],
  computed: {
    signal() {
      if (this.network >= 20) {
        return "strong";
      } else if (this.network < 20 && this.network >= 11) {
        return "medium";
      } else if (this.networkValue > 0){
        return "weak";
      }else if (this.networkValue == -1){
        return "No Signal";
      }else {
        return "Offline";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.signal {
  display: inline-block;
  margin: 0 10px;
  vertical-align: middle;
}
.signal-icon {
  height: 16px;
  width: 15px;
  /* To show you the power of flexbox! */
  display: flex;
  /* Bars should be placed left to right */
  flex-direction: row;
  /* Evenly space the bars with space in between */
  justify-content: space-between;
  /* Sink the bars to the bottom, so they go up in steps */
  align-items: baseline;
}
.signal-icon .signal-bar {
  /* 4 + 3 + 4 + 3 + 4 = 18px (as set above)
     4px per bar and 3px margins between */
  width: 3px;
  /* All bars faded by default */
  opacity: 0.2 !important;
  /* Choose a color */
  background: #019713;
}

/* 3 different heights for 3 different bars */
.signal-icon .signal-bar:nth-child(1) {
  height: 25%;
}
// .signal-icon .signal-bar:nth-child(2) {
//   height: 40%;
// }
.signal-icon .signal-bar:nth-child(2) {
  height: 65%;
}

// .signal-icon .signal-bar:nth-child(4) {
//   height: 70%;
// }

.signal-icon .signal-bar:nth-child(3) {
  height: 100%;
}

// .lw {
//   background: #cf0000 !important;
// }
// .mid {
//   background: #cf5300 !important;
// }
/* Emphasize different bars depending on
   weak/medium/strong classes */
.signal-icon.weak .signal-bar:nth-child(1),
.signal-icon.medium .signal-bar:nth-child(1),
.signal-icon.medium .signal-bar:nth-child(2),
.signal-icon.strong .signal-bar:nth-child(1),
.signal-icon.strong .signal-bar:nth-child(2),
.signal-icon.strong .signal-bar:nth-child(3) {
  opacity: 1 !important;
}
</style>