<template>
  <div>
    <ClientTable />
  </div>
</template>

<script>
import ClientTable from "../components/client/ClientTable.vue";
export default {
  components: {
    ClientTable,
  },
};
</script>

<style lang="scss" scoped>
</style>