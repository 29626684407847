<template>
  <div class="my-profile">
    <h2>Settings</h2>
    <div class="links my-3">
      <router-link class="link-item" to="/settings/account"
        >Account Settings</router-link
      >
      <router-link
        class="link-item"
        to="/settings/manage-user"
        v-if="user.access_level == 2"
        >Manage Users</router-link
      >
      <router-link class="link-item" to="/settings/help"
        >Help & FAQs</router-link
      >
    </div>
    <v-divider style="background-color: #9e9e9e" class="mb-5"></v-divider>
    <div class="account my-5">
      <v-row class="mt-10">
        <v-col cols="3" class="image-col" v-if="user.access_level == 1"
          ><img
            v-if="user.cad == ''"
            src="/img/mk/image.jpg"
            width="100%"
            alt=""
          />
          <img v-else :src="this.$serverUrl + user.cad" width="100%" alt="" />
          <div class="c-name">
            <strong>{{ form.name }}</strong>
          </div>
        </v-col>
        <v-col cols="4">
          <v-row align="center">
            <v-col>
              <div class="profile-img">
                <img :src="logo" alt="" />
              </div>
              <h3 class="mb-3">User Information</h3>
              <p><strong>Name:</strong> {{ form.name }}</p>
              <p><strong>Email:</strong> {{ user.email }}</p>
              <p><strong>Password: **********</strong></p>
            </v-col>
            <v-col
              ><span @click="edit">
                <CustomButton
                  label="Edit"
                  :white="true"
                  icon="mdi-square-edit-outline" /></span
            ></v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      class="custom-dialog"
      transition="dialog-bottom-transition"
      max-width="450"
      v-model="editProfile"
      @click:outside="close"
    >
      <div class="close text-right">
        <span @click="close"><img src="/img/x.svg" width="15" alt="" /></span>
      </div>
      <div class="edit-profile">
        <h3>Change password</h3>
        <v-spacer />
        <v-form ref="form">
          <v-text-field
            class="my-5 rounded-0"
            label="Name"
            type="text"
            color="#000000"
            ref="name"
            :rules="nameRules"
            v-model="user.name"
            disabled
            outlined
            required
          ></v-text-field>
          <v-text-field
            class="my-5 rounded-0"
            label="Email"
            type="text"
            color="#000000"
            ref="email"
            v-model="user.email"
            disabled
            outlined
            required
          ></v-text-field>
          <v-text-field
            class="my-5 rounded-0"
            label="Enter old password"
            type="password"
            color="#000000"
            ref="oldpassword"
            v-model="form.oldpassword"
            outlined
            required
          ></v-text-field>
          <v-text-field
            class="my-5 rounded-0"
            label="Enter new password"
            type="text"
            color="#000000"
            ref="newpassword"
            v-model="form.newpassword"
            outlined
            required
          ></v-text-field>
          <div class="text-center my-5">
            <span @click="submit"> <CustomButton label="Update" /></span>
          </div>
        </v-form>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import CustomButton from "../common/CustomButton.vue";
// import SnackBar from "../common/SnackBar.vue";
export default {
  name: "MyAccount",
  components: {
    CustomButton,
    // SnackBar,
  },
  data() {
    return {
      logo: localStorage.getItem("logo"),
      nameRules: [(v) => !!v || "Name is required"],
      passwordRules: [(v) => !!v || "Password is required"],
      form: {
        oldpassword: "",
        newpassword: "",
      },
      user: null,
      formHasErrors: false,
      editProfile: false,
    };
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.form.name = this.user.name;
  },
  methods: {
    edit() {
      this.editProfile = true;
    },
    close() {
      this.editProfile = false;
    },
    logout() {
      debugger;
      localStorage.removeItem("user_token");
      localStorage.removeItem("user");
      this.$router.push("/login");
      this.$store.commit("SET_SNACKBAR", {
        active: true,
        message: "Please login with the updated password",
        time: 10000,
      });
    },
    async submit(e) {
      e.preventDefault();
      this.formHasErrors = false;

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true;
        this.$refs["name"].validate(true);
        this.$refs["newpassword"].validate(true);

        // console.log(this.form[f]);
      });

      if (this.formHasErrors == false) {
        if (this.user.access_level == 2) {
          this.$axios
            .put("/admin/changepassword", {
              email: this.user.email,
              oldpassword: this.form.oldpassword,
              newpassword: this.form.newpassword,
            })
            .then((res) => {
              console.log(res);
              if (res.status == 200) {
                this.$store.commit("SET_SNACKBAR", {
                  active: true,
                  message: "Password Updated successfully",
                });
                this.$refs.form.reset();
                this.close();
                setTimeout(() => {
                  this.logout();
                }, 3000);
              }
            });
        } else {
          this.$axios
            .put("/user/changepassword", {
              email: this.user.email,
              oldpassword: this.form.oldpassword,
              newpassword: this.form.newpassword,
            })
            .then((res) => {
              console.log(res);
              if (res.status == 200) {
                this.$store.commit("SET_SNACKBAR", {
                  active: true,
                  message: "Password Updated successfully",
                });
                this.$refs.form.reset();
                this.close();
                setTimeout(() => {
                  this.logout();
                }, 3000);
              }
            });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.my-profile {
  padding: 50px;
  margin-top: 20px !important;
  // width: 91%;
  // margin-right: auto;
  h2 {
    margin-bottom: 30px;
  }
  .image-col {
    margin-right: 25px;
    .c-name {
      display: block;
      margin-top: 25px;
      padding: 5px 15px;
      border: 0.75px solid #ccc;
    }
    img {
      padding: 15px;
      -webkit-box-shadow: 0px 0px 14px -6px rgba(112, 112, 112, 1);
      -moz-box-shadow: 0px 0px 14px -6px rgba(112, 112, 112, 1);
      box-shadow: 0px 0px 14px -6px rgba(112, 112, 112, 1);
    }
  }
  .links {
    .link-item {
      &:first-child {
        padding-left: 0;
      }
      cursor: pointer;
      display: inline-block;
      padding: 0 25px;
      opacity: 0.5;
      transition: opacity 0.4s ease-in-out;
      &:hover {
        opacity: 1;
      }
    }
    .link-item.router-link-exact-active {
      font-weight: 600;
      opacity: 1;
    }
  }
  .profile-img {
    img {
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 50%;
      padding: 2px;
      width: 90px;
      height: 90px;
      margin-bottom: 25px;
    }
  }
  .user-info {
    h3 {
      margin-bottom: 10px;
    }
  }
}
</style>