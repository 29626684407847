<template>
  <div>
    <v-dialog
      class="custom-dialog"
      transition="dialog-bottom-transition"
      max-width="450"
      v-model="this.$store.state.staffDialog"
      @click:outside="close"
    >
      <div class="close text-right">
        <span @click="close"><img src="/img/x.svg" width="15" alt="" /></span>
      </div>
      <h3 class="text-center my-5">Add client</h3>
      <v-form ref="form">
        <v-text-field
          class="my-5 rounded-0"
          label="Enter username *"
          type="text"
          color="#000000"
          ref="username"
          v-model="form.username"
          :rules="nameRules"
          outlined
          required
        ></v-text-field>
        <v-text-field
          class="my-5 rounded-0"
          label="Enter email *"
          type="text"
          color="#000000"
          ref="email"
          v-model="form.email"
          :rules="emailRules"
          outlined
          required
        ></v-text-field>
        <v-file-input
          class="my-5 rounded-0"
          label="Logo (optional 192x192 pixels)"
          type="file"
          v-model="logo"
          :rules="imageRules"
          @change="handleLogoUpload"
          prepend-inner-icon="mdi-account-circle-outline"
          prepend-icon=""
          outlined
        ></v-file-input>
        <v-file-input
          class="my-5 rounded-0"
          label="MAP (optional)"
          :rules="imageRules"
          type="file"
          v-model="cad"
          @change="handleFileUpload"
          prepend-inner-icon="mdi-office-building-marker-outline"
          prepend-icon=""
          outlined
        ></v-file-input>
        <v-text-field
          class="my-5 rounded-0"
          label="Enter password *"
          type="text"
          color="#000000"
          ref="password"
          :rules="passRules"
          v-model="form.password"
          append-icon="mdi-lock-reset"
          @click:append="generateP"
          outlined
          required
        ></v-text-field>
      </v-form>
      <div class="text-center my-5">
        <span @click="confirmed"
          ><CustomButton label="Submit" :white="true"
        /></span>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import CustomButton from "../common/CustomButton.vue";
export default {
  components: {
    CustomButton,
  },
  data() {
    return {
      valid: false,
      logo: "",
      cad: "",
      // file: "",
      logoPath: "",
      cadPath: "",
      nameRules: [(v) => !!v || "Name is required"],
      emailRules: [(v) => !!v || "Email is required"],
      passRules: [(v) => !!v || "Password is required"],
      imageRules: [
        (value) =>
          !value ||
          value.size < 1000000 ||
          "image size should be less than 1 MB!",
      ],
      form: {
        username: "",
        email: "",
        password: "",
      },
      formHasErrors: false,
    };
  },
  methods: {
    handleLogoUpload(file) {
      this.logo = file;
      this.upload(1, file);
    },
    handleFileUpload(file) {
      this.cad = file;
      this.upload(2, file);
    },
    upload(type, file) {
      const formData = new FormData();

      formData.append("file", file);
      // debugger;
      if (file != null) {
        this.$axios.post("/admin/user/upload", formData).then((res) => {
          console.log(res);
          if (type == 1) {
            this.logoPath = res.data.filePath;
          } else {
            this.cadPath = res.data.filePath;
          }
          // console.log("IMAGE ==>", res);
        });
      }
    },
    close() {
      this.$store.commit("SET_STAFF_DIALOG", false);
    },
    generateP() {
      var pass = "";
      var str =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZ" +
        "abcdefghijklmnopqrstuvwxyz0123456789@#$";

      for (var i = 1; i <= 8; i++) {
        var char = Math.floor(Math.random() * str.length + 1);

        pass += str.charAt(char);
      }

      this.form.password = pass;
    },
    makeid() {
      var text = "";
      var possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (var i = 0; i < 5; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    },
    async confirmed(e) {
      // debugger;
      e.preventDefault();
      this.formHasErrors = false;
      console.log("FORM REEF:", this.$refs["email"].validate(true));
      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true;
        // this.$refs.form.validate();
        this.$refs["username"].validate(true);
        this.$refs["email"].validate(true);
        this.$refs["password"].validate(true);
      });

      if (this.formHasErrors == false) {
        this.$axios
          .post("/admin/user/register", {
            name: this.form.username,
            email: this.form.email,
            password: this.form.password,
            logo: this.logoPath,
            cad: this.cadPath,
            // imgPath: filename,
          })
          .then((res) => {
            if (res.status == 200) {
              this.$emit("added_staff");
              this.$refs.form.reset();
              this.close();
            }
          })
          .catch((err) => console.log(err));
      }
    },
  },
};
</script>
<style lang="scss">
.v-dialog {
  background: #fff;
  padding: 25px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  .close {
    span {
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss" scoped>
.custom-dialog {
}
</style>