<template>
  <div class="help-page">
    <h2>Settings</h2>
    <div class="links my-3">
      <router-link class="link-item" to="/settings/account"
        >Account Settings</router-link
      >
      <router-link
        class="link-item"
        to="/settings/manage-user"
        v-if="user.access_level == 2"
        >Manage Users</router-link
      >
      <router-link class="link-item" to="/settings/help"
        >Help & FAQs</router-link
      >
    </div>
    <v-divider style="background-color: #9e9e9e"></v-divider>
    <br />
    <div class="qna">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header class="font-weight-bold"
            >What is AQI ?</v-expansion-panel-header
          >
          <v-expansion-panel-content class="my-5">
            <p>Description</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
// import DeviceStatusNote from "./devices/DeviceStatusNote.vue";
export default {
  components: {
    // DeviceStatusNote,
  },
  data() {
    return {
      user: null,
    };
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
};
</script>
<style lang="scss">
.theme--light.v-expansion-panels .v-expansion-panel {
  //   background-color: #f4f4f4 !important;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 20px;
  padding: 10px;
}
.theme--light.v-expansion-panels.v-expansion-panels--focusable
  .v-expansion-panel-header:hover:before {
  opacity: 0 !important;
}
</style>
<style lang="scss" scoped>
.help-page {
  padding: 50px;
  margin-top: 20px !important;
  // width: 91%;
  // margin-right: auto;
  h2 {
    margin-bottom: 30px;
  }
  .links {
    .link-item {
      &:first-child {
        padding-left: 0;
      }
      cursor: pointer;
      display: inline-block;
      padding: 0 25px;
      opacity: 0.5;
      transition: opacity 0.4s ease-in-out;
      &:hover {
        opacity: 1;
      }
    }
    .link-item.router-link-exact-active {
      font-weight: 600;
      opacity: 1;
    }
  }
}
.custom-container {
  width: 90%;
  margin: 25px auto;
  h1 {
    text-align: center;
    margin: 50px 0 !important;
  }
  .qna {
    img {
      margin: 15px;
      padding: 15px;
      border: 0.75px solid #e3e3e3;
      max-width: 100%;
    }
  }
}
</style>