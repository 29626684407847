import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Search from '../views/SearchPaired.vue'
import PairNew from '../views/PairNew.vue'
import ScanDevice from '../views/ScanDevice.vue'
import DeviceDetails from '../views/DeviceDetails'
import Devices from '../views/Devices.vue'
import DeviceData from '../views/DeviceData.vue'
import Locations from '../views/Locations.vue'
import LocationDetails from '../views/LocationDetails'
import Staffs from '../views/Staffs.vue'
import MyAccount from "../views/MyAccount.vue"
import Help from "../views/Help.vue"
import Clients from "../views/Clients.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/clients',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      layout: "no-header"
    }
  },
  {
    path: '/clients',
    name: 'Clients',
    component: Clients,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/searchpaired',
    name: 'SearchPairedDevice',
    component: Search,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pairnew',
    name: 'PairNewDevice',
    component: PairNew,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/devices/:client',
    name: 'Devices',
    component: Devices,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/devices/scan',
    name: 'ScanDevice',
    component: ScanDevice,
    meta: {
      requiresAuth: true,
      layout: 'no-header'
    }
  },
  {
    path: '/devices/:client/controls/:id',
    name: 'DeviceControls',
    component: DeviceDetails,
    meta: {
      requiresAuth: true, layout: 'no-header'
    }
  },
  {
    path: '/devices/:client/data/:id',
    name: 'DeviceData',
    component: DeviceData,
    meta: {
      requiresAuth: true, layout: 'no-header'
    }
  },
  // {
  //   path: '/devices/data/:id',
  //   name: 'DeviceData',
  //   component: DeviceData,
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  {
    path: '/locations',
    name: 'Locations',
    component: Locations,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/locations/:id',
    name: 'LocationDetails',
    component: LocationDetails,
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: '/clients',
  //   name: 'Clients',
  //   component: Staffs,
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  {
    path: '/settings/account',
    name: 'MyAccount',
    component: MyAccount,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings/manage-user',
    name: 'ManageUsers',
    component: Staffs,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings/help',
    name: 'HelpPage',
    component: Help,
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: '/help',
  //   name: 'HelpPage',
  //   component: Help,
  //   meta: {
  //     requiresAuth: true
  //   }
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('user_token') == null) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
