<template>
  <div class="device-info">
    <div class="device-info-details">
      <div class="staff-actions">
        <div class="headings">
          <div class="mt-5 headline headline-text">Welcome!</div>
          <div class="mt-3 subtitle-1 headline-subtext">{{ user.name }}</div>
        </div>
        <div class="actions">
          <v-row>
            <v-col>
              <span @click="addStaff"
                ><CustomButton label="Add Client" icon="mdi-plus" :white="true"
              /></span>
            </v-col>
            <v-col>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                color="#000000"
                @input="filter"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="cards">
        <div class="card" v-for="c in filterClients" :key="c._id">
          <ClientCard
            :client="c"
            @remove="deleteItemStaff"
            @updatePage="getUsers"
          />
        </div>
      </div>
    </div>
    <AdminDialog @added_admin="adminAdded" />
    <StaffDialog @added_staff="staffAdded" />
    <!-- <EditUserDialog @password_reset="passwordUpdated" /> -->
    <ConfirmDialog
      message="This action will also delete all the device for this client, please make you have downloaded a latest data export first."
      @confirmed="ConfirmDeleteStaff"
    />
    <!-- <SnackBar /> -->
  </div>
</template>

<script>
// import CustomButton from "../common/CustomButton.vue";
import AdminDialog from "../dialogs/AdminDialog.vue";
import StaffDialog from "../dialogs/StaffDialog.vue";
import ConfirmDialog from "../dialogs/ConfirmDialog.vue";
import CustomButton from "../common/CustomButton.vue";
import ClientCard from "./ClientCard.vue";
import { PF } from "../../../config/accPath";
export default {
  components: {
    // CustomButton,
    AdminDialog,
    StaffDialog,
    ConfirmDialog,
    ClientCard,
    CustomButton,
  },
  data() {
    return {
      serverUploadPath: PF,
      search: "",
      filterClients: [],
      staffs: [],
      editItem: "",
      images: [],
      imgPath: "",
      user: null,
    };
  },
  mounted() {
    this.getAdmins();
    this.getUsers();
    this.getImage(this.impPath);
  },
  methods: {
    filter() {
      if (this.search != "") {
        this.filterClients = this.staffs;
        // console.log(this.search);
        this.filterClients = this.filterClients.filter((item) => {
          return (
            item.name.toLowerCase().includes(this.search.toLowerCase()) 
            // ||
            // item.email.toLowerCase().includes(this.search.toLowerCase())
          );
        });
      } else {
        this.filterClients = this.staffs;
      }
    },
    getImage(x) {
      const img = this.serverUploadPath + "/uploads/" + x;
      return img;
    },
    passwordUpdated() {
      this.$store.commit("SET_SNACKBAR", {
        active: true,
        message: "Password Updated Successfully",
      });
    },
    getAdmins() {
      this.$axios
        .get("/admin/all")
        .then((res) => {
          if (res.status == 200) {
            this.admins = res.data.users;
          }
        })
        .catch((err) => console.log(err));
    },
    getUsers() {
      this.user = JSON.parse(localStorage.getItem("user"));
      this.$axios
        .get("/admin/user/all")
        .then((res) => {
          this.filterClients = res.data.users;
          this.staffs = res.data.users;

          this.all = this.admins.concat(this.staffs);

          console.log("ALL ", this.all);
        })
        .catch((err) => console.log(err));
    },
    addAdmin() {
      this.$store.commit("SET_ADMIN_DIALOG", true);
    },
    addStaff() {
      this.$store.commit("SET_STAFF_DIALOG", true);
    },
    authorizeAdmin(id, authorized) {
      this.$axios
        .put("/admin/", {
          id: id,
          authorized: authorized,
        })
        .then((res) => {
          console.log(res);
          this.$store.commit("SET_SNACKBAR", {
            active: true,
            message: "Admin Updated Successfully",
          });
          this.$emit("data_changed");
        })
        .catch((err) => console.log(err));
    },
    authorizeStaff(id, authorized) {
      this.$axios
        .put("/admin/user/", {
          id: id,
          authorized: authorized,
        })
        .then((res) => {
          console.log(res);
          this.$store.commit("SET_SNACKBAR", {
            active: true,
            message: "Staff Updated Successfully",
          });
          this.$emit("data_changed");
        })
        .catch((err) => console.log(err));
    },
    editItemAdmin(item) {
      this.$store.commit("SET_EDIT_USER_DIALOG", {
        status: true,
        user: item,
        type: "admin",
      });
    },
    editItemStaff(item) {
      this.$store.commit("SET_EDIT_USER_DIALOG", {
        status: true,
        user: item,
        type: "staff",
      });
    },
    deleteItemAdmin(email) {
      this.editUser = "admin";
      this.editItem = email;
      this.$store.commit("SET_CONFIRM_DIALOG", true);
    },
    deleteItemStaff(email) {
      this.editUser = "staff";
      this.editItem = email;
      this.$store.commit("SET_CONFIRM_DIALOG", true);
    },
    ConfirmDeleteStaff() {
      if (this.editUser == "staff") {
        this.$axios
          .delete("/admin/user/", {
            data: {
              email: this.editItem,
            },
          })
          .then((res) => {
            console.log(res);
            // this.$emit("data_changed");
            this.getUsers();
          })
          .catch((err) => console.log(err));
      } else {
        this.$axios
          .delete("/admin/", {
            data: {
              email: this.editItem,
            },
          })
          .then((res) => {
            console.log(res);
            // this.$emit("data_changed");
            this.getAdmins();
          })
          .catch((err) => console.log(err));
      }
    },
    staffAdded() {
      this.$store.commit("SET_SNACKBAR", {
        active: true,
        message: "Client registered successfully",
      });
      // this.$emit("data_changed");
      this.getUsers();
    },
    adminAdded() {
      this.$store.commit("SET_SNACKBAR", {
        active: true,
        message: "Admin registered successfully",
      });
      this.$emit("data_changed");
    },
  },
};
</script>
<style lang="scss">
.headline-text {
  font-weight: 600 !important;
}
.headline-subtext {
  font-weight: 500 !important;
}
.avatar {
  object-fit: cover;
  width: 120px;
}
.v-tabs-bar {
  min-height: 48px !important;
  height: unset !important;
}
.v-tabs-bar__content {
  margin: 15px;
}
.v-tabs-slider-wrapper {
  display: none;
}
.v-tab {
  background: #fff !important;
  color: #000 !important;
  padding: 11px !important;
  margin-right: 15px !important;
  border-radius: 31px;
  border: 1px solid #000 !important;
}
.v-tab.v-tab--active {
  background: #000 !important;
  color: #fff !important;
  border: 1px solid transparent !important;
}
.v-slide-group__wrapper {
  border-bottom: 2px solid #e3e3e3 !important;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}
table tbody tr td {
  font-size: 14px !important;
  padding: 15px !important;
}
table thead th {
  font-size: 16px !important;
  color: #000 !important;
  padding: 20px !important;
}
</style>
<style lang="scss" scoped>
.device-info {
  .feature-img {
    height: 350px;
    background: url(/img/praanbg.jpg), rgba(0, 0, 0, 0.6);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    display: flex;
    align-items: center;
    .device-status {
      max-width: 100%;
      flex: 0 0 100%;
      color: #fff;
      padding: 50px;
      .device-name {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 25px;
      }
      .device-active {
        font-size: 26px;
        font-weight: bold;
        span.active {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background: #00be16;
          display: inline-block;
          vertical-align: middle;
          margin: 15px;
        }
      }
      .device-inactive {
        font-size: 26px;
        font-weight: bold;
        span.inactive {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background: red;
          display: inline-block;
          vertical-align: middle;
          margin: 15px;
        }
      }
    }
  }
  .device-info-details {
    position: relative;
    // max-width: calc(100% - 186px);
    margin-right: auto;
    background: #fff;
    // top: -40px;
    h2 {
      margin-bottom: 20px;
    }
    .cards {
      display: flex;
      flex-wrap: wrap;
      margin: 0 20px;
      width: 100%;
      // justify-content: space-around;
      .card {
        flex: 0 0 29%;
        max-width: 29%;
        margin: 20px 36px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 12px;
      }

      @media only screen and (min-width: 700px) and (max-width: 1100px) {
        .card {
          flex: 0 0 47% !important;
          max-width: 47% !important;
          margin: 20px 5px;
          box-shadow: 0px 3px 6px #00000029;
          border-radius: 12px;
        }
      }
    }
    .staff-actions {
      margin: 20px 20px;
      padding: 15px;
      .headings {
        border-bottom: 1px solid #bbbbbb;
        padding-bottom: 10px;
      }
      .actions {
        margin: 25px 0;
      }
    }
    .info-data {
      background: #fff;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 10px;
      max-width: 90%;
      margin: auto;
      padding: 50px;
      margin-bottom: 25px;
    }
  }
}
</style>