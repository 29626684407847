<template>
  <div>
    <LocationInfo
      :info="locationInfo"
      :name="locationName"
      :count="deviceCount"
      :previous="PreviousDeployments"
    />
  </div>
</template>

<script>
import LocationInfo from "../components/locations/LocationInfo.vue";
export default {
  name: "LocationDetails",
  components: {
    LocationInfo,
  },
  data() {
    return {
      locationInfo: null,
      locationName: "",
      deviceCount: 0,
      PreviousDeployments: null,
    };
  },
  mounted() {
    this.getLocInfo();
  },
  methods: {
    getLocInfo() {
      this.$axios
        .get("/device/location?loc=" + this.$route.params.id)
        .then((res) => {
          this.locationInfo = res.data.devices;
          this.deviceCount = res.data.devices.length;
          this.locationName = this.locationInfo[0].currentLocation.locationName;
        })
        .catch((err) => console.log(err));
      this.$axios
        .get("/admin/location/locationinfo?loc_id=" + this.$route.params.id)
        .then((res) => {
          this.PreviousDeployments = res.data.location.previousDeployedDevices;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style lang="scss" scoped>
</style>