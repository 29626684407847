<template>
  <div class="device-info">
    <div class="device-img text-center">
      <GoBack />
      <div class="img">
        <div class="signal text-right">
          <SignalBar :network="data.networkStrength" />
        </div>
        <!-- <img src="/img/mk/mkonest.png" alt="" /> -->
        <img src="/img/mk/ses.png" alt="" v-if="data.deviceName == 'SES_105370'"/>
        <img src="/img/mk/mk2.png" alt="" v-else/>
      </div>
      <div class="device-name">
        {{ data.deviceName }}
        <span v-if="access == 2" class="edit-icon" @click="editName"
          ><v-icon color="#000">mdi-square-edit-outline</v-icon></span
        >
        <span class="zone-name d-block body-2 mt-5">
          <span class="edit-icon">{{ data.zoneName }}</span>
        </span>
      </div>

      <div class="links">
        <router-link
          :to="'/devices/' + $route.params.client + '/data/' + $route.params.id"
          class="link"
        >
          Air Quality Index
        </router-link>
        <router-link
          class="link"
          :to="
            '/devices/' + $route.params.client + '/controls/' + $route.params.id
          "
        >
          Device Controls
          <div
            style="display: inline-block"
            class="ml-1"
            v-if="data.health != 0 || data.controlError != 0"
          >
            <v-icon color="red">mdi-alert-circle-outline</v-icon>
          </div>
        </router-link>
      </div>
    </div>
    <div class="device-aqi">
      <div class="head">
        <h2 v-if="plotTitle != 'Air Quality Index'">{{ plotTitle }}</h2>
        <h2 v-else>
          Air Quality Index
          <a href="/settings/help" v-if="aqiData">
            <v-tooltip right color="#000">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="#000000" v-bind="attrs" v-on="on"
                  >mdi-help-circle-outline</v-icon
                >
              </template>
              <span>
                Know more about<br />
                air quality index (AQI)</span
              >
            </v-tooltip>
          </a>
        </h2>
        <div v-if="aqiData" class="aqi-data" :class="aqiColor">
          AQI index Recorded : <span>{{ aqiData }} ({{ aqiColor }})</span>
        </div>
        <div v-if="noData" class="no-data">No Recorded Data</div>
      </div>
      <v-select
        v-model="selected"
        :items="duration"
        label="duration"
        item-text="text"
        item-value="val"
        return-object
        solo
        class="select-bar"
        v-on:change="changeDuration(`${selected.val}`)"
      ></v-select>
      <div class="chart">
        <!-- <area-chart
          v-if="loaded"
          :data="plotData"
          height="550px"
          :colors="colors"
          loading="Loading..."
          empty="No Recorded Data"
          :download="{ background: '#999999' }"
          :spanGaps="true"
          :library="options"
          :suffix="suffix"
          legend="bottom"
          :label="plotTitle"
          :points="false"
          :discrete= "false"
          xmin="2022-03-16 09:25:39" xmax="2022-03-16 22:00:39"
          :dataset="{
            type: 'line',
            label: 'online chart',
            data: [10, 20, 30, 40]
        }"
        ></area-chart> -->
        <LineChart
          :styles="myStyles"
          :dataPoints="plotData"
          :offline="offline"
          :suffix="suffix"
          :timeformat="timeformat"
        />
      </div>
      <!-- <div class="legends ">
        <span class="online">online</span><span class="offline">offline</span>
      </div> -->
      <div class="data-tabs">
        <v-btn
          elevation="2"
          v-for="(tab, index) in tabs"
          @click="currentTab = index"
          class="white-bg"
          :class="{ active: currentTab === index }"
          :key="index"
          plain
          :ripple="false"
          >{{ tab }}</v-btn
        >
      </div>

      <!-- <div style="width: 94%" v-if="aqiData">
        <div class="mt-5" style="display: inline-block">
          <a href="/settings/help">
            <v-card class="ma-2 pa-2" :elevation="4">
              <v-row class="text-center">
                <v-col class="d-flex align-self-start" cols="2">
                  <v-icon color="#000000">mdi-help-circle-outline</v-icon>
                </v-col>
                <v-col class="d-flex align-self-start">
                  <div style="text-align: left; font-size: 12px">
                    Know more about<br />
                    air quality index (AQI)
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </a>
        </div>
      </div> -->
    </div>

    <EditDeviceDialog
      :name="data.deviceName"
      :deviceNo="data.deviceNo"
      :zoneName="data.zoneName"
      @name_updated="nameUpdate"
    />
  </div>
</template>

<script>
// import CustomButton from "../common/CustomButton.vue";
// import UnpairDialog from "../dialogs/UnpairDialog.vue";
// import PairDialog from "../dialogs/PairDialog.vue";
// import ConfirmDialog from "../dialogs/ConfirmDialog.vue";
// import DeviceStatusTransparent from "./DeviceStatusTransparent.vue";
import EditDeviceDialog from "../dialogs/EditDeviceName.vue";
import GoBack from "../common/GoBack.vue";
import SignalBar from "./SignalBar.vue";
// import ApexCharts from "apexcharts";
import LineChart from "./LineChart.vue";
export default {
  props: ["data"],
  components: {
    // CustomButton,
    // UnpairDialog,
    // PairDialog,
    // ConfirmDialog,
    // DeviceStatusTransparent,
    EditDeviceDialog,
    GoBack,
    SignalBar,
    LineChart,
  },
  data() {
    return {
      myStyles: {
        height: "600px",
        width: "100%",
        position: "relative",
        "box-shadow": "inset 0 0 8px 0px #2a2a2a",
        padding: "20px 10px 5px 10px",
      },
      network: 0,
      aqiShow: true,
      loaded: false,
      ledColor: "#3194C9",
      row: "radio-2",
      form: {
        offset: "",
      },
      // items: ["5 minutes", "10 minutes", "15 minutes", "30 minutes", "1 hour"],
      chartData: [],
      timeformat: {},
      offline: [],
      aqi: [],
      aqiData: null,
      aqiColor: "",
      p1data: [],
      p25data: {},
      p10data: {},
      gas: {},
      humidity: {},
      temperature: {},
      sound: {},
      uv: {},
      ir: {},
      vis: {},
      allData: [
        { name: "PM 1.0", data: {} },
        { name: "PM 2.5", data: {} },
        { name: "PM 10.0", data: {} },
      ],
      colors: ["#d0d0d0"],
      tabs: [
        "AQI",
        "PM 1.0",
        "PM 2.5",
        "PM 10.0",
        "Humidity",
        "Temperature",
        "Gas",
        "Noise",
        "UV",
        "IR",
        "VIS",
      ],
      currentTab: 0,
      plotTitle: "PM 1.0",
      duration: [
        { text: "Last Hour", val: 2 },
        { text: "Today", val: 5 },
        { text: "Last Day", val: 1 },
        { text: "Last 7 Days", val: 3 },
        { text: "Last 30 Days", val: 4 },
      ],
      selected: { text: "Last Day", val: 5 },
      noData: false,
      suffix: "ug/m3",
      options: {
        backgroundColor: "#000000",
        animation: { easing: "easeOutQuad" },
        elements: { line: { borderWidth: 3 } },
        responsive: true,
        spanGaps: true,
        scales: {
          xAxes: [
            {
              ticks: {
                //min: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
                //max: new Date(new Date().getTime())
              },
            },
          ],
        },
      },
    };
  },
  mounted() {
    this.getHourlyData(this.selected.val);
  },
  computed: {
    access() {
      let user = JSON.parse(localStorage.getItem("user"));
      return user.access_level;
    },
    plotData: function () {
      let dta = this.aqi;
      // let title = "";
      if (this.currentTab === 0) {
        dta = this.aqi;
      } else if (this.currentTab === 1) {
        dta = this.p1data;
      } else if (this.currentTab === 2) {
        dta = this.p25data;
      } else if (this.currentTab === 3) {
        dta = this.p10data;
      } else if (this.currentTab === 4) {
        dta = this.humidity;
      } else if (this.currentTab === 5) {
        dta = this.temperature;
      } else if (this.currentTab === 6) {
        dta = this.gas;
      } else if (this.currentTab === 7) {
        dta = this.sound;
      } else if (this.currentTab === 8) {
        dta = this.uv;
      } else if (this.currentTab === 9) {
        dta = this.ir;
      } else if (this.currentTab === 10) {
        dta = this.vis;
      } else {
        dta = this.aqi;
      }
      return dta;
    },
  },
  watch: {
    currentTab: function () {
      this.getHourlyData(this.selected.val);
    },
    aqi: function () {
      if (this.aqi.length > 0) {
        this.aqiData = Math.max.apply(
          Math,
          this.aqi.map(function (o) {
            return o.y;
          })
        );

        // var total = 0;
        // for (var i = 0; i < this.aqi.length; i++) {
        //   total += this.aqi[i].y;
        // }
        // this.aqiData = Math.round(total / this.aqi.length);
      }
    },
    aqiData: function () {
      this.aqiColor = this.getAqiQuality();
    },
  },
  methods: {
    changeDuration(val) {
      if (val > 2) {
        this.timeformat = { unit: "day", unitStepSize: 1 };
      } else {
        this.timeformat = {};
      }
      this.getHourlyData(val);
    },
    getHourlyData(val) {
      this.loaded = false;
      this.$axios
        .get(
          "/device/hourlydata?deviceId=" +
            this.$route.params.id +
            "&duration=" +
            val
        )
        .then((res) => {
          if (res.data.Data != 0) {
            this.noData = false;
            this.resetChart();
            this.chartData = res.data.Data;
            if (this.currentTab === 0) {
              this.plotTitle = "Air Quality Index";
              this.calculateSelected("aqi", "aqi");
              this.suffix = "ug/m3";
            } else if (this.currentTab === 1) {
              this.plotTitle = "PM 1.0";
              this.calculateSelected("p1data", "averagep1");
              this.suffix = "ug/m3";
            } else if (this.currentTab === 2) {
              this.plotTitle = "PM 2.5";
              this.calculateSelected("p25data", "averagep25");
              this.suffix = "ug/m3";
            } else if (this.currentTab === 3) {
              this.plotTitle = "PM 10.0";
              this.calculateSelected("p10data", "averagep10");
              this.suffix = "ug/m3";
            } else if (this.currentTab === 4) {
              this.plotTitle = "Humidity";
              this.calculateSelected("humidity", "averagehu");
              this.suffix = "%RH";
            } else if (this.currentTab === 5) {
              this.plotTitle = "Temperature";
              this.calculateSelected("temperature", "averaget");
              this.suffix = "℃";
            } else if (this.currentTab === 6) {
              this.plotTitle = "Gas";
              this.calculateSelected("gas", "averageg");
              this.suffix = "ug/m3";
            } else if (this.currentTab === 7) {
              this.plotTitle = "Noise";
              this.calculateSelected("sound", "sound");
              this.suffix = "dB";
            } else if (this.currentTab === 8) {
              this.plotTitle = "UV";
              this.calculateSelected("uv", "uv");
              this.suffix = "";
            } else if (this.currentTab === 9) {
              this.plotTitle = "IR";
              this.calculateSelected("ir", "ir");
              this.suffix = "";
            } else if (this.currentTab === 10) {
              this.plotTitle = "VIS";
              this.calculateSelected("vis", "vis");
              this.suffix = "";
            } else {
              this.plotTitle = "PM 1.0";
              this.calculateSelected("p1data", "averagep1");
              this.suffix = "ug/m3";
            }
          } else {
            this.noData = true;
            this.resetChart();
          }

          this.loaded = true;
        })
        .catch((err) => console.log(err));
    },
    resetChart() {
      this.aqi = [];
      this.p1data = [];
      this.p25data = [];
      this.p10data = [];
      this.gas = [];
      this.humidity = [];
      this.temperature = [];
      this.sound = [];
      this.uv = [];
      this.ir = [];
      this.vis = [];
      this.offline = [];
    },
    calculateSelected(variable, selector) {
      // console.log(selector);
      if (variable != "aqi") {
        this.aqiData = null;
      }
      for (var i = 0; i <= this.chartData.length - 1; i++) {
        this.calculateData(
          new Date(this.chartData[i].ts),
          this.chartData[i][selector],
          variable,
          i,
          selector
        );
      }
    },
    calculateData(time, val, variable, index, selector) {
      // calculateData(time, val, variable) {
      // console.log(variable);
      // parseInt(this.chartData[i].averagep1.toFixed(2))
      // debugger
      this[variable].push({
        t: time,
        y: val,
      });
      // this.offline.push({
      //   t: time,
      //   y: null,
      // });
      if (!val) {
        // debugger
        if (index > 0 && index < this.chartData.length - 1) {
          if (this.chartData[index - 1][selector] != null) {
            this.offline.push({
              t: new Date(this.chartData[index - 1].ts),
              y: this.chartData[index - 1][selector],
            });
          }
          if (this.chartData[index + 1][selector] != null) {
            this.offline.push({
              t: new Date(this.chartData[index + 1].ts),
              y: this.chartData[index + 1][selector],
            });
          }
        } else {
          if (index == this.chartData.length - 1) {
            this.offline.push({
              t: new Date(this.chartData[index].ts),
              y: 0,
            });
          } else {
            this.offline.push({
              t: new Date(this.chartData[index].ts),
              y: 0,
            });
            this.offline.push({
              t: new Date(this.chartData[index + 1].ts),
              y: this.chartData[index + 1][selector],
            });
          }
        }
      } else {
        // this[variable].push({
        //   t: time,
        //   y: val,
        // });
        this.offline.push({
          t: time,
          y: null,
        });
      }
    },

    editName() {
      this.$store.commit("SET_EDIT_DEVICE_DIALOG", true);
    },
    nameUpdate(deviceId) {
      this.$store.commit("SET_SNACKBAR", {
        active: true,
        message: "Device updated successfully",
      });
      if (deviceId != null) {
        // console.log("device:", deviceId);
        this.$router.replace({ params: { id: deviceId } });
      }
      this.$emit("data_changed");
    },
    getAqiQuality() {
      if (this.aqiData <= 50) return "Good";
      else if (this.aqiData <= 100) return "Satisfactory";
      else if (this.aqiData <= 200) return "Moderate";
      else if (this.aqiData <= 300) return "Poor";
      else if (this.aqiData <= 400) return "Very-Poor";
      else if (this.aqiData > 400) return "Severe";
      else return "";
    },
  },
};
</script>
<style lang="scss">
// .led-path {
//   fill: red !important;
// }
.legends {
  margin-bottom: 15px;
  margin-top: -15px;
  
  font-size: 12px;
  span {
    display: inline-block;
    margin: 15px;
    position: relative;
  }
  span.online::before {
    content: "";
    width: 100%;
    position: absolute;
    border-bottom: 1px solid #adadad;
    top: -10px;
  }
  span.offline::before {
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23ADADADFF' stroke-width='3' stroke-dasharray='5%2c15' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    top: -10px;
  }
}
.Good {
  background-color: #cbff94;
}
.Satisfactory {
  background-color: #bdff71;
}
.Moderate {
  background-color: #ffea75;
}
.Poor {
  background-color: #ffd862;
}
.Very-Poor {
  background-color: #ffc1c1;
}
.Severe {
  background-color: #fc4a4a;
}

.aqi-data {
  // background-color: #f0f0f0;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 2px;
  margin-top: 15px;
  font-size: 14px;
}
.white-bg span {
  opacity: 1 !important;
}
.theme--light.v-btn.v-btn--has-bg {
  background-color: #ffffff;
}
.head {
  // text-align: center;
  width: 94%;
  .no-data {
    color: #f17070;
    padding-top: 10px;
  }
}
.hideTab {
  display: none;
}
.showTab {
  display: block;
}
.data-tabs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // width:94%;
  align-content: center;
  justify-content: center;
  gap: 15px;
  .active {
    background-color: #000000 !important;
    color: #ffffff !important;
  }
}
.select-bar {
  width: 170px;
  position: absolute !important;
  top: 0px;
  right: 75px;
  // font-size: 12px !important;
  .v-list-item__content {
    font-size: 12px !important;
  }
}
#ledcolor {
  -webkit-appearance: none;
  padding: 0;
  border: none;
  border-radius: 30px;
  width: 60px;
  height: 30px;
  position: relative;
  top: 8px;
  left: 9px;
}
#ledcolor::-webkit-color-swatch {
  border: none;
  border-radius: 30px;
  padding: 0;
}
#ledcolor::-webkit-color-swatch-wrapper {
  border: none;
  border-radius: 30px;
  padding: 0;
}
.qr-img {
  img {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
</style>
<style lang="scss" scoped>
// #chart-div {
//   width: 80% !important;
//   margin-top: 50px;
//   margin-left: 40px;
//   margin-right: auto;
// }
.device-info {
  display: flex;
  .device-aqi {
    width: 100%;
    min-height: 60vh;
    display: flex;
    align-items: center;
    margin: 40px 0;
    flex-direction: column;
    position: relative;
    .chart {
      padding: 40px 40px;
      width: 100% !important;
      min-height: 630px;
    }
  }
  .device-stats {
    width: 100%;
    .controls {
      padding: 45px;
      .control-row {
        // min-height: 100vh;
        .col-control {
          min-height: 350px;
        }
      }
      .control-head {
        font-size: 24px;
        font-weight: 500;
      }
      .control-sub {
        font-size: 20px;
        font-weight: 500;
      }
      .control-desc {
        color: #00000090;
        margin-top: 20px;
      }
      .switch-toggle {
        float: left;
        background: #fff;
        max-width: 250px;
        margin-top: 20px;
        border-radius: 20px;
        border: 0.5px solid #ccc;
      }
      .switch-toggle input {
        position: absolute;
        opacity: 0;
      }
      .switch-toggle input + label {
        padding: 7px;
        float: left;
        color: #000;
        width: 70px;
        text-align: center;
        cursor: pointer;
        border-radius: 20px;
      }
      .switch-toggle input:checked + label {
        background: #000;
        color: #fff;
      }
      .switches.sm {
        max-width: 100px;
      }
      .switches {
        max-width: 200px;
        margin-top: 20px;
        border-radius: 20px;
        border: 0.5px solid #ccc;
        display: flex;
        justify-content: space-between;
        span {
          padding: 5px 15px;
          cursor: pointer;
        }
        span.active {
          background: #000;
          color: #fff;
          border-radius: 20px;
        }
      }
      .switch-div {
        width: 100%;
        display: block;
      }
      .led-div {
        width: 100%;
        display: inline-block;
      }
      .led {
        margin-top: 20px;
        display: inline-block;
      }
    }
  }
  .device-img {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 45px;
    min-height: 100vh;
    background: rgb(240, 240, 240) !important;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    .img {
      img {
        max-width: 60%;
      }
    }
    .device-name {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 40px;
    }
    .edit-icon {
      background: rgb(210, 210, 210) !important;
      padding: 5px;
      margin-left: 10px;
      border-radius: 2px;
      cursor: pointer;
    }
    .links {
      margin-top: 10px;
      a.link {
        min-width: 140px;
        background: #fff;
        margin-bottom: 20px;
        display: block !important;
        padding: 15px;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.4s ease-in-out;
        &:hover {
          background: #000;
          color: #fff !important;
        }
      }
      .link.router-link-exact-active {
        background: #000;
        color: #fff !important;
      }
    }
  }
  .feature-img {
    min-height: 350px;
    background: url(/img/praanbg.jpg), rgba(0, 0, 0, 0.6);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    display: flex;
    align-items: center;
    .device-heading {
      max-width: 100%;
      flex: 0 0 100%;
      color: #fff;
      padding: 20px 4%;
      .device-name {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 5px;
      }
    }
    //   .device-status {
    //     max-width: 100%;
    //     flex: 0 0 100%;
    //     color: #fff;
    //     padding: 50px;
    //     .device-name {
    //       font-size: 22px;
    //       font-weight: bold;
    //       margin-bottom: 25px;
    //     }
    //     .device-active {
    //       font-size: 26px;
    //       font-weight: bold;
    //       span.active {
    //         width: 15px;
    //         height: 15px;
    //         border-radius: 50%;
    //         background: #00be16;
    //         display: inline-block;
    //         vertical-align: middle;
    //         margin: 15px;
    //       }
    //     }
    //     .device-inactive {
    //       font-size: 26px;
    //       font-weight: bold;
    //       span.inactive {
    //         width: 15px;
    //         height: 15px;
    //         border-radius: 50%;
    //         background: red;
    //         display: inline-block;
    //         vertical-align: middle;
    //         margin: 15px;
    //       }
    //     }
    //   }
  }
  .device-info-details {
    position: relative;
    top: -40px;
    .info-data {
      background: #fff;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 10px;
      max-width: 90%;
      margin: auto;
      padding: 50px;
      margin-bottom: 25px;
    }
    .hide {
      display: none;
    }
  }
}
</style>