<template>
  <div class="go-back" @click="goBack">
    <span
      ><v-icon class="mr-2" color="#000" style="margin-right: 0 !important"
        >mdi-arrow-left</v-icon
      ></span
    >
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    goBack() {
      var user = JSON.parse(localStorage.getItem("user"));
      if (user.access_level == 2) {
        // console.log("ROute:" ,this.$route.params)
        this.$router.push("/devices/"+ this.$route.params.client);
      } else {
        this.$router.push("/devices/" + user._id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.go-back {
  position: absolute;
  top: 25px;
  left: 25px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 6px #00000029;
}
</style>