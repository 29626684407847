<template>
  <div>
    <DeviceDataTable
      :data="deviceInfo"
      @data_changed="getDeviceInfo"
      
    />
  </div>
</template>

<script>
// :netstrength="network"
import DeviceDataTable from "../components/devices/DeviceDataTable.vue";
export default {
  name: "DeviceData",
  components: {
    DeviceDataTable,
  },
  data() {
    return {
      deviceInfo: null,
      network: 0,
      deviceLoc: ""
    };
  },
  mounted() {
    this.getDeviceInfo();
    // this.getNetwork();
  },
  methods: {
    getDeviceInfo() {
      this.$axios
        .get("/device?deviceNo=" + this.$route.params.id)
        .then((res) => {
          this.deviceInfo = res.data.device;
        })
        .catch((err) => console.log(err));
    },
   
  },
};
</script>

<style lang="scss" scoped>
</style>