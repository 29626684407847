<template>
  <div>
    <ScanQr />
  </div>
</template>

<script>
import ScanQr from "../components/scanner/ScanQr.vue";
export default {
  name: "ScanDevice",
  components: {
    ScanQr,
  },
};
</script>

<style lang="scss" scoped>
</style>