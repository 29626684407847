<template>
  <div class="location-page">
    <div class="text-center">
      <span @click="addNewLocation"
        ><CustomButton :white="true" label="Add a new location"
      /></span>
    </div>

    <div class="location-list">
      <div class="search-bar">
        <v-text-field
          class="my-5 rounded-0"
          label="Type location name to Search"
          type="text"
          color="#000000"
          ref="location"
          @input="filter"
          v-model="searchlocation"
          outlined
          required
        ></v-text-field>
      </div>
      <div v-if="filteredLocations.length !== 0">
        <LocationCard v-for="n in filteredLocations" :key="n.id" :data="n" />
      </div>
      <div v-else class="text-center">
        <h3>No locations found</h3>
      </div>
    </div>
    <LocationDialog @added_location="locationAdded" />
    <!-- <SnackBar /> -->
  </div>
</template>

<script>
import LocationCard from "../components/locations/LocationCard.vue";
import LocationDialog from "../components/dialogs/NewLocationDialog.vue";
import CustomButton from "../components/common/CustomButton.vue";
// import SnackBar from "../components/common/SnackBar.vue";
export default {
  name: "Location",
  components: {
    LocationCard,
    LocationDialog,
    CustomButton,
    // SnackBar,
  },
  data() {
    return {
      locations: [],
      filteredLocations: [],
      searchlocation: "",
    };
  },
  mounted() {
    this.getLocations();
  },
  methods: {
    getLocations() {
      this.$axios
        .get("/admin/location/all")
        .then((res) => {
          this.locations = res.data.locations;
          this.filteredLocations = res.data.locations;
        })
        .catch((err) => console.log(err));
    },
    filter() {
      if (this.searchlocation != "") {
        this.filteredLocations = this.filteredLocations.filter((item) => {
          //  debugger
          return item.locationName
            .toLowerCase()
            .includes(this.searchlocation.toLowerCase());
        });
      } else {
        this.filteredLocations = this.locations;
      }
    },
    addNewLocation() {
      this.$store.commit("SET_LOCATION_DIALOG", true);
    },
    locationAdded() {
      this.$store.commit("SET_SNACKBAR", {
        active: true,
        message: "Location added successfully",
      });
      this.getLocations();
    },
  },
};
</script>

<style lang="scss" scoped>
.location-page {
  margin-top: 60px;
  .search-bar {
    max-width: 60%;
    margin: auto;
  }
}
</style>