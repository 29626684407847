<template>
  <div>
    <v-dialog
      class="custom-dialog"
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="this.$store.state.additonalControlDialog"
      @click:outside="close"
    >
      <div class="close text-right">
        <span @click="close"><img src="/img/x.svg" width="15" alt="" /></span>
      </div>
      <div class="control-head text-center font-weight-bold headline mb-5">
        Additional Controls
      </div>
      <v-row align="center">
        <v-col>
          <p class="control-desc">
            The Device is publishing data every 10 minutes.
          </p>
          <v-select
            :items="items"
            v-model="form.pub"
            label="Select to change Publish Rate"
            outlined
          ></v-select>
          <!-- <span class="control-sub">Power Status : On</span> -->
          <!-- <p class="control-desc">Collection Chamber is 29% full.</p> -->
          <br />
          <p class="control-desc">Fan Speed</p>
          <v-radio-group v-model="form.fan" row>
            <v-radio label="Off" color="#000" value="0"></v-radio>
            <v-radio label="Low" color="#000" value="25"></v-radio>
            <v-radio label="Med" color="#000" value="50"></v-radio>
            <v-radio label="High" color="#000" value="100"></v-radio>
          </v-radio-group>
          <div class="switch-div">
            <span class="led"
              >Ionizer :
              <v-switch
                color="#000000"
                class="d-inline-block justify-left"
                v-model="form.ion"
                @change="ionChange"
                inset
                :label="`${ionStatus.toString()}`"
              ></v-switch>
            </span>
          </div>
        </v-col>
      </v-row>
      <div class="text-center my-5">
        <span @click="confirmed"
          ><CustomButton label="Confirm" :white="true"
        /></span>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import CustomButton from "../common/CustomButton.vue";
export default {
  name: "additonalControlDialog",
  props: ["pub", "fan", "ion"],
  components: {
    CustomButton,
  },
  data() {
    return {
      ionStatus: "OFF",
      items: [
        { text: "5 minutes", value: 5 },
        { text: "10 minutes", value: 10 },
        { text: "15 minutes", value: 15 },
        { text: "30 minutes", value: 30 },
        { text: "1 hour", value: 60 },
      ],
      form: {
        ion: this.ion,
        pub: this.pub,
        fan: this.fan,
      },
      initialion: this.ion,
    };
  },
  mounted() {
    if (this.ion != false) {
      this.form.ion = true;
      this.ionStatus = "ON";
    }
  },
  methods: {
    ionChange() {
      if (this.form.ion == true) {
        this.ionStatus = "ON";
        this.form.ion = true;
      } else {
        this.ionStatus = "OFF";
        this.form.ion = false;
      }
    },
    close() {
      this.form.ion = this.initialion;
      this.$emit("notconfirmed");
      this.$store.commit("SET_ADDITIONAL_CONTROL_DIALOG", false);
    },
    confirmed() {
      this.$emit("confirmed", this.form);
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>