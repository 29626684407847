<template>
  <div>
    <PairNew />
  </div>
</template>

<script>
import PairNew from "../components/devices/PairNew.vue";
export default {
  name: "PairNewDevice",
  components: {
    PairNew,
  },
};
</script>

<style lang="scss" scoped>
</style>