<template>
  <div>
    <StaffTable />
  </div>
</template>

<script>
import StaffTable from "../components/staffs/StaffTable.vue";
export default {
  name: "Staffs",
  components: {
    StaffTable,
  },
  
};
</script>

<style lang="scss" scoped>
</style>