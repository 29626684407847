<template>
  <div class="search-device">
    <h2>Search a Paired Device</h2>
    <v-spacer />
    <v-form>
      <v-text-field
        class="my-5 rounded-0"
        label="Device ID"
        type="text"
        color="#000000"
        ref="deviceid"
        v-model="form.deviceid"
        outlined
        required
      ></v-text-field>
      <v-text-field
        class="my-5 rounded-0"
        label="Device Name"
        type="text"
        color="#000000"
        ref="deviceid"
        v-model="form.deviceid"
        outlined
        required
      ></v-text-field>
      <v-text-field
        class="my-5 rounded-0"
        label="Select Location"
        type="text"
        color="#000000"
        ref="deviceid"
        v-model="form.deviceid"
        outlined
        required
      ></v-text-field>
      <v-text-field
        class="my-5 rounded-0"
        label="Enter Offset"
        type="text"
        color="#000000"
        ref="deviceid"
        v-model="form.deviceid"
        outlined
        required
      ></v-text-field>
      <div class="text-center my-5">
        <span @click="login"> <CustomButton label="Submit" /></span>
      </div>
    </v-form>
  </div>
</template>

<script>
import CustomButton from "../common/CustomButton.vue";
export default {
  name: "Search",
  components: {
    CustomButton,
  },
  data() {
    return {
      form: {
        deviceid: "",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.search-device {
  max-width: 550px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 21px;
  padding: 50px;
  margin-top: 120px !important;
  margin: auto;
}
</style>