<template>
  <div class="manage-user">
    <h2>Settings</h2>
    <div class="links my-3">
      <router-link class="link-item" to="/settings/account"
        >Account Settings</router-link
      >
      <router-link
        class="link-item"
        to="/settings/manage-user"
        v-if="user.access_level == 2"
        >Manage Users</router-link
      >
      <router-link class="link-item" to="/settings/help"
        >Help & FAQs</router-link
      >
    </div>
    <v-divider style="background-color: #9e9e9e"></v-divider>
    <br />
    <div class="pa-0">
      <v-card-title>
        <v-text-field
          v-model="searchAdmin"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <span @click="addAdmin"
          ><CustomButton :white="true" icon="mdi-plus" label="Add admin"
        /></span>
      </v-card-title>
      <br/>
      <v-data-table
        :headers="headers"
        :items="admins"
        :items-per-page="15"
        :search="searchAdmin"
        class="elevation-0 text-center"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon class="mr-3" color="#418FBF" @click="editItemAdmin(item)">
            mdi-lock-reset
          </v-icon>
          <v-icon color="#DD2525" @click="deleteItemAdmin(item.email)">
            mdi-trash-can-outline
          </v-icon>
        </template>
        <template v-slot:item.authorized="{ item }">
          <v-switch
            v-model="item.authorized"
            @change="authorizeAdmin(item._id, item.authorized)"
          ></v-switch>
        </template>
      </v-data-table>
    </div>
    <AdminDialog @added_admin="adminAdded" />
    <EditAdminDialog @password_reset="passwordUpdated" />
    <ConfirmDialog
      message="Confirm to delete?"
      @confirmed="ConfirmDeleteStaff"
    />
  </div>
</template>

<script>
import CustomButton from "../common/CustomButton.vue";
import AdminDialog from "../dialogs/AdminDialog.vue";
import ConfirmDialog from "../dialogs/ConfirmDialog.vue";
import EditAdminDialog from "../dialogs/EditAdminDialog.vue";
export default {
  components: {
    CustomButton,
    AdminDialog,
    ConfirmDialog,
    EditAdminDialog,
  },
  data() {
    return {
      searchAdmin: "",
      headers: [
        {
          text: "User name",
          value: "name",
          align: "center",
        },
        { text: "Email", value: "email", align: "center" },
        { text: "Authorized", value: "authorized" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      admins: [],
      user: null,
      editItem: "",
    };
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.getAdmins();
  },
  methods: {
    passwordUpdated() {
      this.$store.commit("SET_SNACKBAR", {
        active: true,
        message: "Password Updated Successfully",
      });
    },
    getAdmins() {
      this.$axios
        .get("/admin/all")
        .then((res) => {
          if (res.status == 200) {
            this.admins = res.data.users;
          }
        })
        .catch((err) => console.log(err));
    },
    addAdmin() {
      this.$store.commit("SET_ADMIN_DIALOG", true);
    },
    authorizeAdmin(id, authorized) {
      this.$axios
        .put("/admin/", {
          id: id,
          authorized: authorized,
        })
        .then((res) => {
          console.log(res);
          this.$store.commit("SET_SNACKBAR", {
            active: true,
            message: "Admin Updated Successfully",
          });
          this.$emit("data_changed");
        })
        .catch((err) => console.log(err));
    },
    editItemAdmin(item) {
      this.$store.commit("SET_EDIT_ADMIN_DIALOG", {
        status: true,
        user: item,
        type: "admin",
      });
    },
    deleteItemAdmin(email) {
      this.editUser = "admin";
      this.editItem = email;
      this.$store.commit("SET_CONFIRM_DIALOG", true);
    },
    ConfirmDeleteStaff() {
      if (this.editUser == "staff") {
        this.$axios
          .delete("/admin/user/", {
            data: {
              email: this.editItem,
            },
          })
          .then((res) => {
            console.log(res);
            // this.$emit("data_changed");
            this.getUsers();
          })
          .catch((err) => console.log(err));
      } else {
        this.$axios
          .delete("/admin/", {
            data: {
              email: this.editItem,
            },
          })
          .then((res) => {
            console.log(res);
            // this.$emit("data_changed");
            this.getAdmins();
          })
          .catch((err) => console.log(err));
      }
    },
    adminAdded() {
      this.$store.commit("SET_SNACKBAR", {
        active: true,
        message: "Admin registered successfully",
      });
      this.getAdmins();
      this.$emit("data_changed");
    },
  },
};
</script>
<style lang="scss">
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}
table tbody tr td {
  font-size: 14px !important;
  padding: 15px !important;
}
table thead th {
  font-size: 16px !important;
  color: #000 !important;
  padding: 20px !important;
}
</style>
<style lang="scss" scoped>
.manage-user {
  padding: 50px;
  margin-top: 20px !important;
  // width: 91%;
  // margin-right: auto;
  h2 {
    margin-bottom: 30px;
  }
  .links {
    .link-item {
      &:first-child {
        padding-left: 0;
      }
      cursor: pointer;
      display: inline-block;
      padding: 0 25px;
      opacity: 0.5;
      transition: opacity 0.4s ease-in-out;
      &:hover {
        opacity: 1;
      }
    }
    .link-item.router-link-exact-active {
      font-weight: 600;
      opacity: 1;
    }
  }
}
</style>