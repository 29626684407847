<template>
  <dashboard :totalDevice="counts.deviceCount" :activeDevice="counts.activeDevices" :inactiveDevices="counts.inactiveDevices" :staffs="counts.staffs" :locations="counts.locationCount"/>
</template>

<script>
import Dashboard from "../components/Dashboard.vue";

export default {
  name: "Home",

  components: {
    Dashboard,
  },
   data() {
    return {
      counts: null,
    };
  },
  mounted() {
    this.getCounts();
  },
  methods: {
    getCounts() {
      this.$axios
        .get("/counts")
        .then((res) => {
          this.counts = res.data;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
