<template>
  <div class="device-list">
    <div class="admin my-4 mb-6" v-if="access == 2">
      <h2>All Devices</h2>
      <div class="add">
        <span @click="addDevice">
          <CustomButton label="Add Device" icon="mdi-plus" :white="true" />
        </span>
      </div>
    </div>
    <div class="client my-4" v-else>
      <div class="mt-5 headline headline-text">Welcome!</div>
      <div class="mt-2 subtitle-1 headline-subtext">{{ name }}</div>
    </div>
    <v-divider style="background-color: #9e9e9e"></v-divider>
    <v-row align="center">
      <v-col
        ><v-card-title class="normal-font"
          >Total devices : &nbsp;<strong>{{
            devices.length
          }}</strong></v-card-title
        ></v-col
      >
      <v-col
        ><v-text-field
          class="my-5 rounded-0"
          append-icon="mdi-magnify"
          label="Search"
          type="text"
          color="#000000"
          @input="filter"
          v-model="searchDevices"
          required
        ></v-text-field
      ></v-col>
    </v-row>
    <div class="admin" v-if="access == 2">
      <div v-if="devices.length != 0">
        <DeviceCard
          v-for="(n, index) in filteredDevices"
          :key="index"
          :data="n"
          :access="access"
          @device_removed="getAllDevices"
        />
      </div>
      <div class="text-center ma-5" v-else>
        <strong>No devices</strong>
      </div>
    </div>
    <div class="client" v-else>
      <v-row v-if="devices.length != 0">
        <v-col
          cols="12"
          xl="4"
          md="6"
          lg="4"
          v-for="(n, index) in filteredDevices"
          :key="index"
        >
          <DeviceClientCard
            :data="n"
            :access="access"
            @device_removed="getAllDevices"
          />
        </v-col>
      </v-row>
      <div class="text-center ma-5" v-else>
        <strong>No devices</strong>
      </div>
    </div>

    <AddDeviceDialog @device_added="getAllDevices" />
  </div>
</template>

<script>
import DeviceCard from "../components/devices/DeviceCard.vue";
import DeviceClientCard from "../components/devices/DeviceClientCard.vue";
import CustomButton from "../components/common/CustomButton.vue";
import AddDeviceDialog from "../components/dialogs/AddDeviceDialog.vue";
export default {
  name: "Devices",
  props: ["access"],
  components: {
    DeviceCard,
    DeviceClientCard,
    CustomButton,
    AddDeviceDialog,
  },
  // watch: {
  //   $route(to) {
  //     this.activeTab = to.query.devicetype;
  //   },
  // },
  computed: {
    name() {
      var user = JSON.parse(localStorage.getItem("user"));
      return user.name;
    },
  },
  data() {
    return {
      devices: [],
      filteredDevices: [],
      searchDevices: "",
    };
  },
  mounted() {
    this.activeTab = parseInt(this.$route.query.devicetype);
    this.getAllDevices();
    const startd = this.$moment(
      new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000)
    )
      .utc()
      .startOf("day")
      .toDate();
    console.log('START',startd);
    const endd = this.$moment(
      new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000)
    )
      .endOf("day")
      .utcOffset("+05:30")
      .toDate();
    console.log("END", endd);
  },
  methods: {
    filter() {
      if (this.searchDevices != "") {
        this.filteredDevices = this.devices;
        this.filteredDevices = this.filteredDevices.filter((item) => {
          //  debugger
          return (
            item.zoneName
              .toLowerCase()
              .includes(this.searchDevices.toLowerCase()) ||
            item.deviceName
              .toLowerCase()
              .includes(this.searchDevices.toLowerCase())
          );
          // return item.zoneName != null
          //   ? item.zoneName
          //       .toLowerCase()
          //       .includes(this.searchDevices.toLowerCase())
          //   : "";
        });
      } else {
        this.filteredDevices = this.devices;
      }
    },
    addDevice() {
      this.$store.commit("SET_ADD_DIALOG", true);
    },
    getAllDevices() {
      this.$axios
        .get("device/admin/all?clientid=" + this.$route.params.client)
        .then((res) => {
          this.devices = res.data.devices;
          this.filteredDevices = res.data.devices;

          // this.active = this.devices.filter((item) => {
          //   return item.activated == true && item.logStatus == true;
          // });
          // this.inactive = this.devices.filter((item) => {
          //   return item.activated == false || item.logStatus == false;
          // });
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style lang="scss" >
.normal-font {
  font-weight: 400;
  font-size: 16px !important;
}
.device-list {
  padding: 15px 50px;
  margin-top: 20px !important;
  // width: 91%;
  // margin-right: auto;
  h2 {
    margin-bottom: 20px;
  }
  .add {
    // padding-left: 80px;
    // padding-bottom: 25px;
  }
  .v-slide-group__wrapper {
    border-bottom: 0px solid #e3e3e3 !important;
    max-width: 90%;
    margin: 15px auto;
  }
}
</style>