<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  props: ["dataPoints", "offline", "suffix", "timeformat"],
  // mixins: [mixins.reactiveProp],
  mounted() {
    const gradient = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);
    gradient.addColorStop(0, "rgba(197,197,197, 1)");
    gradient.addColorStop(0.7, "rgba(197,197,197,0.6)");
    gradient.addColorStop(1, "rgba(197,197,197, 0.2)");

    this.data.datasets[0].backgroundColor = gradient;

    this.renderChart(this.data, this.options);
  },
  data() {
    return {
      gradient: null,
      data: {
        datasets: [
          {
            label: "online",
            spanGaps: false,
            borderColor: "#d0d0d0",
            pointBackgroundColor: "#ffffff",
            borderWidth: 0.5,
            pointBorderColor: "rgba(197,197,197, 1)",
            backgroundColor: null,

            data: [
              // { t: new Date("2022-3-16 12:12"), y: 15 },
              // { t: new Date("2022-3-16 13:12"), y: 20 },
              // { t: new Date("2022-3-16 14:12"), y: 25 },
              // { t: new Date("2022-3-16 15:12"), y: 20 },
              // { t: new Date("2022-3-16 16:12"), y: 15 },
              // { t: new Date("2022-3-16 17:30"), y: 10 },
              // { t: new Date("2022-3-16 18:12"), y: null },
              // { t: new Date("2022-3-16 19:12"), y: 20 },
              // { t: new Date("2022-3-16 20:12"), y: 24 },
              // { t: new Date("2022-3-16 21:12"), y: 27 },
            ],
          },
          {
            label: "Offline",
            borderColor: "#c5c5c5",
            pointBackgroundColor: "white",
            pointBorderColor: "white",
            borderWidth: 1,
            backgroundColor: "transparent",
            borderDash: [5, 10],
            data: [
              // { t: new Date("2022-3-16 17:30"), y: 10 },
              // { t: new Date("2022-3-16 19:12"), y: 20 },
            ],
          },
        ],
      },
      options: {
        // spanGaps:true,
        connectNullData: true,
        responsive: true,
        maintainAspectRatio: false,
        height: 600,
        scaleLabel: " ug/m3",
        tooltips: {
          enabled: true,
          intersect: false,
          mode: "single",
          callbacks: {
            label: function (tooltipItems) {
              return tooltipItems.yLabel + " ug/m3";
            },
          },
        },
        legend: false,
        // legend: {
        //   position: "bottom",
        // },
        scales: {
          xAxes: [
            {
              type: "time",
              // time: {
              //   unit: "day",
              //   displayFormats: {
              //     day: "MMM DD",
              //   },
              // },
              time: {},

              gridLines: {
                display: false,
                color: "#FFFFFF",
              },
            },
          ],
          yAxes: [
            {
              grace: "5%",
              gridLines: {
                borderDash: [8, 4],
                color: "#eeeeee",
              },
              ticks: {
                // callback: function (label) {
                //   return label + " ug/m3";
                // },
                beginAtZero: true,
                // stepSize: 10,
              },
            },
          ],
        },
      },
    };
  },
  watch: {
    dataPoints: {
      immediate: true,
      handler(newVal) {
        this.data.datasets[0].data = newVal;

        this.renderGraph();
      },
    },
    offline: {
      immediate: true,
      handler(newVal) {
        this.data.datasets[1].data = newVal;

        this.renderGraph();
      },
    },
    suffix: {
      immediate: true,
      handler(newVal) {
        this.options.tooltips.callbacks = {
          label: function (tooltipItems) {
            return tooltipItems.yLabel + " " + newVal;
          },
        };

        this.renderGraph();
      },
    },
    timeformat: {
      immediate: true,
      handler(newVal) {
        this.options.scales.xAxes[0].time = newVal;
        this.data.datasets.slice(0, 1);

        this.renderGraph();
      },
    },
    // dataPoints:function() {
    //   this.renderChart(this.data, this.options);
    // },
  },
  methods: {
    renderGraph() {
      // this._chart.destroy();
      // this.$data._chart.destroy()
      // this.renderChart(this.data, this.options);
      // this.$data._chart.update();
      this.renderChart(this.data, this.options);
    },
  },
};
</script>
