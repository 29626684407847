import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'font-awesome/css/font-awesome.css'
import DrawerLayout from 'vue-drawer-layout'
import axios from './plugins/axios'
import '@mdi/font/css/materialdesignicons.css'
import VueQRCodeComponent from 'vue-qr-generator'
import VueHtmlToPaper from 'vue-html-to-paper';
import JsonExcel from "vue-json-excel";
import moment from "vue-moment";
import { Chart } from 'chart.js'
import Chartkick from 'vue-chartkick'
import InputColorPicker from "vue-native-color-picker";
Vue.use(InputColorPicker);
Vue.use(Chartkick.use(Chart));
Vue.use(require('vue-moment'));
Vue.component("downloadExcel", JsonExcel);

Vue.prototype.moment = moment
// Vue.prototype.$serverUrl = "http://localhost:8000/uploads/"
Vue.prototype.$serverUrl = "https://mkone.praan.io/server/"

// Vue.use(VueMoment)
const options = {
  name: 'Device QR Code',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=no'
  ],
  styles: [
    '/css/qr.css',
  ]
}
Vue.use(VueHtmlToPaper, options);
Vue.component('qr-code', VueQRCodeComponent)
//Drawer Layout for Sidebar
Vue.use(DrawerLayout)
//Making Axios Default
Vue.use(axios)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
