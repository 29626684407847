<template>
  <div>
    <Login />
  </div>
</template>

<script>
import Login from "../components/authentication/Login.vue";
export default {
  components: {
    Login,
  },
  mounted() {
    if (localStorage.getItem("user_token" != null)) {
      this.$router.push("/");
    } else {
      this.$router.next();
    }
  },
};
</script>

<style lang="scss" scoped>
</style>