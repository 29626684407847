var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"device-status"},[(!_vm.device.activated || !_vm.device.logStatus)?_c('div',{staticClass:"inactive"},[_c('span'),_vm._v("Inactive ")]):(
      _vm.device.logStatus == true &&
      _vm.device.currentLocation !== null &&
      _vm.device.lastPushData == null &&
      _vm.offline == false
    )?_c('div',{staticClass:"awaiting"},[_c('span'),_vm._v(" Awaiting data ")]):(
      _vm.device.lastPushData != null &&
      _vm.device.currentLocation !== null &&
      _vm.device.logStatus == true &&
      _vm.offline == false
    )?_c('div',{staticClass:"active"},[_c('span'),_vm._v("Active ")]):(
      _vm.device.logStatus == true &&
      _vm.device.lastPushAt != null &&
      _vm.device.lastPushData != null &&
      _vm.offline == true
    )?_c('div',{staticClass:"offline"},[_c('span'),_vm._v("Offline ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }