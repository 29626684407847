<template>
  <div>
    <v-dialog
      class="custom-dialog text-center"
      transition="dialog-bottom-transition"
      max-width="450"
      persistent
      v-model="this.$store.state.controlDialog.status"
    >
      <h3 class="text-center my-5">
        {{ this.$store.state.controlDialog.message }}
      </h3>
      <div class="text-center mb-5">
        <v-progress-circular
          :size="50"
          color="#000000DE"
          indeterminate
        ></v-progress-circular>
      </div>
      <div class="text-center mb-3">
        <h5>
          Please make sure device is switched on and functioning properly.
        </h5>
      </div>
      <div class="text-center mb-3" v-if="access == 2">
        <span @click="resetDevice">
          <CustomButton label="Hard reset" :red="true"
        /></span>
      </div>
      <div class="text-center">
        <router-link
          :to="'/devices/' + $route.params.client + '/data/' + $route.params.id"
          ><CustomButton label="View AQI" :white="true"
        /></router-link>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import CustomButton from "../common/CustomButton.vue";
export default {
  name: "controlDialog",
  props: ["access", "deviceNo"],
  components: {
    CustomButton,
  },
  methods: {
    close() {
      this.$store.commit("SET_CONTROL_DIALOG", { status: false, message: "" });
    },
    resetDevice() {
      this.$axios
        .post("/device/control?deviceId=" + this.deviceNo, {
          lid: 1,
          ion: 0,
          frequency: 10,
          fan: "0",
          led: "#______",
        })
        .then((res) => {
          this.$store.commit("SET_SNACKBAR", {
            active: true,
            message: "Device reseted successfully.",
          });
          console.log(res);
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
<style lang="scss">
.v-dialog {
  background: #fff;
  padding: 25px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  .close {
    span {
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss" scoped>
.custom-dialog {
}
</style>