<template>
  <div>
    <v-dialog
      class="custom-dialog text-center"
      transition="dialog-bottom-transition"
      max-width="450"
      persistent
      v-model="this.$store.state.errorHighDialog.status"
    >
      <div class="text-center">
        <v-icon color="red" size="50">mdi-alert-circle-outline</v-icon>
      </div>
      <h3 class="text-center my-5">
        {{ this.$store.state.errorHighDialog.message }}
      </h3>
      <!-- <div class="text-center mb-5">
        <v-progress-circular
          :size="50"
          color="#000000DE"
          indeterminate
        ></v-progress-circular>
      </div> -->
      <v-row justify="center">
        <v-col cols="5"
          ><div class="text-center mb-5">
            <a :href="'mailto:mkonesupport@praan.io?subject=Issue with device controls - '+this.deviceId+'&body=Error message : '+this.$store.state.errorHighDialog.message+'%0D%0A%0D%0A'">
              <CustomButton label="Send a mail" :white="true"
            /></a></div
        ></v-col>
        <v-col cols="5"
          ><div class="text-center">
            <router-link
              :to="
                '/devices/' + $route.params.client + '/data/' + $route.params.id
              "
              ><CustomButton label="View AQI" :white="true"
            /></router-link></div
        ></v-col>
      </v-row>
    </v-dialog>
  </div>
</template>

<script>
import CustomButton from "../common/CustomButton.vue";
export default {
  name: "errorHighDialog",
  props:["deviceId"],
  components: {
    CustomButton,
  },
  methods: {
    close() {
      this.$store.commit("SET_ERROR_HIGH_DIALOG", {
        status: false,
        message: "",
      });
    },
  },
};
</script>
<style lang="scss">
.v-dialog {
  background: #fff;
  padding: 25px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  .close {
    span {
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss" scoped>
.custom-dialog {
}
</style>