<template>
  <div>
    <DeviceInfo
      v-if="deviceInfo != null"
      :data="deviceInfo"
      @data_changed="getDeviceInfo"
    />
    <ControlUpdationDialog :access="access" :deviceNo="deviceInfo.deviceNo" />
    <ErrorHighDialog
      v-if="deviceInfo != null"
      :deviceId="deviceInfo.deviceNo"
    />
  </div>
</template>

<script>
import DeviceInfo from "../components/devices/DeviceInfo.vue";
import ControlUpdationDialog from "../components/dialogs/ControlUpdationDialog.vue";
import ErrorHighDialog from "../components/dialogs/ErrorHighDialog.vue";
export default {
  name: "DeviceDetails",
  components: {
    DeviceInfo,
    ControlUpdationDialog,
    ErrorHighDialog,
  },
  data() {
    return {
      deviceInfo: null,
    };
  },
  computed: {
    access() {
      let user = JSON.parse(localStorage.getItem("user"));
      return user.access_level;
    },
  },
  mounted() {
    this.getDeviceInfo();
    // this.getNetwork();
    this.keepchecking();
  },
  methods: {
    keepchecking() {
      setInterval(() => {
        this.getDeviceInfo();
      }, 60 * 1000);
    },
    getDeviceInfo() {
      this.$axios
        .get("/device?deviceNo=" + this.$route.params.id)
        .then((res) => {
          this.deviceInfo = res.data.device;
          if (this.deviceInfo.health != 0) {
            if (this.deviceInfo.health == 1) {
              this.$store.commit("SET_ERROR_HIGH_DIALOG", {
                status: true,
                message:
                  "Lid is stuck please check the device or contact the support team",
              });
            } else if (this.deviceInfo.health == 2) {
              this.$store.commit("SET_ERROR_HIGH_DIALOG", {
                status: true,
                message:
                  "Ionizer is not working, please replug the device, if it happens again contact the support team",
              });
            } else if (this.deviceInfo.health == 3) {
              this.$store.commit("SET_ERROR_HIGH_DIALOG", {
                status: true,
                message:
                  "Fan is not working, please check and contact the team",
              });
            } else if (this.deviceInfo.health == 4) {
              this.$store.commit("SET_SNACKBAR", {
                active: true,
                message: "PM sensor disconnected, please check the connection",
                color: "error",
                time: 60000,
              });
            } else if (this.deviceInfo.health == 5) {
              this.$store.commit("SET_SNACKBAR", {
                active: true,
                message:
                  "Temperature sensor disconnected, please check the connection",
                color: "error",
                time: 60000,
              });
            } else if (this.deviceInfo.health == 6) {
              this.$store.commit("SET_SNACKBAR", {
                active: true,
                message: "Gas sensor disconnected, please check the connection",
                color: "error",
                time: 60000,
              });
            }
          } else if (this.deviceInfo.controlError != 0) {
            if (this.deviceInfo.controlError == 1) {
              this.$store.commit("SET_ERROR_HIGH_DIALOG", {
                status: true,
                message:
                  "There is some issue with the lid, please check the device or contact the support team",
              });
            } else if (this.deviceInfo.controlError == 2) {
              this.$store.commit("SET_ERROR_HIGH_DIALOG", {
                status: true,
                message:
                  "There is some issue with the ionizer,please check the device or contact the support team",
              });
            } else if (this.deviceInfo.controlError == 3) {
              this.$store.commit("SET_ERROR_HIGH_DIALOG", {
                status: true,
                message:
                  "There is some issue with the frequency, please check the device or contact the support team",
              });
            } else if (this.deviceInfo.controlError == 4) {
              this.$store.commit("SET_ERROR_HIGH_DIALOG", {
                status: true,
                message:
                  "There is some issue with fan, please check the device or contact the support team",
              });
            } else if (this.deviceInfo.controlError == 5) {
              this.$store.commit("SET_ERROR_HIGH_DIALOG", {
                status: true,
                message:
                  "There is some issue with device lighting, please check the device or contact the support team",
              });
            }
          } else {
            this.$store.commit("SET_ERROR_HIGH_DIALOG", {
              status: false,
              message: "",
            });
          }
          if (this.deviceInfo.controlUpdating == true) {
            this.$store.commit("SET_CONTROL_DIALOG", {
              status: true,
              message:
                "Please wait while we are updating the device. It might take upto 2 minutes",
            });
          } else {
            this.$store.commit("SET_CONTROL_DIALOG", {
              status: false,
              message: "",
            });
          }
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style lang="scss" scoped>
</style>