<template>
  <div>
    <Account />
  </div>
</template>

<script>
import Account from "../components/staffs/Account.vue";
export default {
  components: {
    Account,
  },
};
</script>

<style lang="scss" scoped>
</style>