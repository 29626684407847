<template>
  <div>
    <v-dialog class="custom-dialog" transition="dialog-bottom-transition" max-width="450" persistent
      v-model="this.$store.state.confirmDialog.status">
      <div class="close text-right">
        <span @click="close"><img src="/img/x.svg" width="15" alt="" /></span>
      </div>
      <h3 class="text-center my-5">
        {{ message }}
      </h3>
      <div class="text-center mb-5" v-if="download">
        <span @click="downloadExport(deviceId, deviceName)">
          <CustomButton label="Download Now" :white="true" icon="mdi-download" />
        </span>
      </div>
      <div class="text-center mb-5">
        <span @click="confirmed" class="mx-3">
          <CustomButton label="Confirm" :white="true" />
        </span>
        <span @click="close" class="mx-3">
          <CustomButton label="Cancel" :red="true" />
        </span>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import CustomButton from "../common/CustomButton.vue";
import FileDownload from "js-file-download";
export default {
  name: "ConfirmDialog",
  props: ["message", "deviceId", "deviceName"],
  components: {
    CustomButton,
  },
  data() {
    return {
      download: false,
    };
  },
  mounted() {
    if (this.deviceId != null) {
      this.download = true;
    } else {
      this.download = false;
    }
  },
  methods: {
    close() {
      this.$emit("notconfirmed");
      this.$store.commit("SET_CONFIRM_DIALOG", {
        status: false,
        devideId: '',
        deviceName: ''
      });
    },
    confirmed() {
      this.$emit("confirmed");
      this.close();
    },
    downloadExport() {
      this.$axios
        .get("/device/exportdata?deviceId=" + this.$store.state.confirmDialog.deviceId)
        .then((res) => {
          if (res.status == 200) {
            var file =
              this.$store.state.confirmDialog.deviceName +
              "-" +
              this.$moment(new Date()).format("MMMM Do YYYY, h:mm:ss A") +
              ".csv";
            FileDownload(res.data, file);
            // console.log("Downloaded");
          }
        });

    },
  },
};
</script>
<style lang="scss">
.v-dialog {
  background: #fff;
  padding: 25px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;

  .close {
    span {
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss" scoped>
.custom-dialog {}
</style>