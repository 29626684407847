<template>
  <div>
    <v-dialog
      class="custom-dialog"
      transition="dialog-bottom-transition"
      max-width="450"
      v-model="this.$store.state.editUserDialog.status"
      @click:outside="close"
    >
      <div class="close text-right">
        <span @click="close"><img src="/img/x.svg" width="15" alt="" /></span>
      </div>
      <h3 class="text-center my-5">Manage Client</h3>
      <!-- <v-text-field
        class="my-5 rounded-0"
        label="Enter username"
        type="text"
        color="#000000"
        ref="username"
        v-model="username"
        outlined
        required
        disabled
      ></v-text-field> -->
      <v-form ref="form">
        <v-text-field
          class="my-0 rounded-0"
          label="Enter email"
          type="text"
          color="#000000"
          ref="email"
          v-model="email"
          outlined
          required
          disabled
        ></v-text-field>
        <v-file-input
          class="my-0 rounded-0"
          label="Change Logo (192x192 pixels)"
          ref="logo"
          type="file"
          v-model="logo"
          @change="handleLogoUpload"
          required
          prepend-inner-icon="mdi-account-circle-outline"
          prepend-icon=""
          outlined
        ></v-file-input>
        <v-file-input
          class="my-0 rounded-0"
          label="Update Map"
          type="file"
          ref="cad"
          v-model="cad"
          @change="handleFileUpload"
          required
          prepend-inner-icon="mdi-office-building-marker-outline"
          prepend-icon=""
          outlined
        ></v-file-input>
        <v-text-field
          class="my-0 rounded-0"
          label="New password"
          type="text"
          color="#000000"
          ref="password"
          v-model="form.password"
          outlined
        ></v-text-field>
        <div class="text-center my-5">
          <span @click="confirmed"
            ><CustomButton label="Update" :white="true"
          /></span>
        </div>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import CustomButton from "../common/CustomButton.vue";
export default {
  props: ["user", "type"],
  components: {
    CustomButton,
  },
  data() {
    return {
      nameRules: [(v) => !!v || "Name is required"],
      emailRules: [(v) => !!v || "Email is required"],
      passRules: [(v) => !!v || "Password is required"],
      imageRules: [
        (value) =>
          !value ||
          value.size < 1000000 ||
          "image size should be less than 1 MB!",
      ],
      formHasErrors: false,
      form: {
        username: "",
        email: "",
        password: "",
        logo: "",
        cad: "",
      },
      logopath: "",
      cadPath: "",
    };
  },
  computed: {
    username() {
      return this.$store.state.editUserDialog.form.username;
    },
    email() {
      return this.$store.state.editUserDialog.form.email;
    },
    usertype() {
      return this.$store.state.editUserDialog.type;
    },
    logo() {
      return this.$store.state.editUserDialog.form.logo;
    },
    cad() {
      return this.$store.state.editUserDialog.form.cad;
    },
  },
  mounted() {},
  methods: {
    handleLogoUpload(file) {
      // this.form.logo = file;
      this.upload(1, file);
    },
    handleFileUpload(file) {
      // this.form.cad = file;
      this.upload(2, file);
    },
    upload(type, file) {
      const formData = new FormData();

      formData.append("file", file);
      // debugger;
      this.$axios.post("/admin/user/upload", formData).then((res) => {
        if (type == 1) {
          this.logopath = res.data.filePath;
        } else {
          this.cadPath = res.data.filePath;
        }
        // console.log("IMAGE ==>", res);
      });
    },
    close() {
      this.$store.commit("SET_EDIT_USER_DIALOG", {
        status: false,
        user: null,
        type: "staff",
      });
    },
    async confirmed() {
      if (this.usertype == "admin") {
        this.$axios
          .put("/admin/user/resetadminpassword", {
            email: this.email,
            newpassword: this.form.password,
          })
          .then((res) => {
            if (res.status == 200) {
              this.$emit("password_reset");
              this.close();
            }
          })
          .catch((err) => console.log(err));
      } else {
        // e.preventDefault();
        // this.formHasErrors = false;

        // Object.keys(this.form).forEach((f) => {
        //   if (!this.form[f]) this.formHasErrors = true;
        //   this.$refs.form.validate();
        // });

        if (this.formHasErrors == false) {
          this.$axios
            .put("/admin/user/resetuserpassword", {
              email: this.email,
              newpassword: this.form.password,
              logo: this.logopath,
              cad: this.cadPath,
            })
            .then((res) => {
              if (res.status == 200) {
                this.$emit("password_reset");
                this.$refs.form.reset();
                this.close();
              }
            })
            .catch((err) => console.log(err));
        }
      }
    },
  },
};
</script>
<style lang="scss">
.v-dialog {
  background: #fff;
  padding: 25px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  .close {
    span {
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss" scoped>
.custom-dialog {
}
</style>