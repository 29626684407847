<template>
  <v-app>
    <div class="not-viewable">
      <h2>
        Please Use a Desktop device or switch to landscape mode to view this
        page
      </h2>
    </div>
    <div class="not-viewable mobile">
      <h2>Please Use a Desktop device to view this page</h2>
    </div>
    <div class="app-container" v-if="this.default">
      <!-- <v-navigation-drawer permanent expand-on-hover>
        
      </v-navigation-drawer> -->
      <div class="side-bar-outer">
        <div class="side-bar">
          <div class="c-logo text-center">
            <img v-if="user.logo" :src="this.$serverUrl + user.logo" alt="" />
            <img v-else src="https://mkone.praan.io/img/praan-sq.png" alt="" />
          </div>
          <v-divider style="background-color: #9e9e9e" class="my-5"></v-divider>
          <div class="list-link">
            <div class="list-nav text-center" v-if="user.access_level == 1">
              <router-link :to="'/devices/' + user._id" class="list-item">
                <v-icon color="#000">mdi-radio-tower</v-icon>
              </router-link>
            </div>
            <div class="list-nav text-center" v-if="user.access_level == 2">
              <router-link to="/clients" class="list-item">
                <v-icon color="#000">mdi-office-building-marker-outline</v-icon>
              </router-link>
            </div>
            <div class="list-nav text-center">
              <router-link to="/settings/account" class="list-item">
                <v-icon color="#000">mdi-cog</v-icon>
              </router-link>
            </div>
          </div>
          <v-divider style="background-color: #9e9e9e" class="mt-5"></v-divider>
          <div class="list-nav text-center">
            <span class="list-item" @click="logout"
              ><v-icon color="#000">mdi-logout-variant</v-icon></span
            >
          </div>

          <div class="logo-dash">
            <img src="https://mkone.praan.io/img/praan-sq.png" width="110" />
          </div>
        </div>
      </div>

      <div class="page">
        <transition name="fade" mode="out-in">
          <router-view :access="user.access_level" />
        </transition>
      </div>
    </div>
    <div v-else class="app-no-header">
      <transition name="fade" mode="out-in">
        <router-view :access="user.access_level" />
      </transition>
    </div>
    <Snackbar />
    <AlertDialog />
    <LoaderDialog />
    <Loading :active.sync="this.$store.state.loaderMainDialog" />
  </v-app>
</template>

<script>
// import Header from "./components/header/Header.vue";
// import SideMenu from "./components/drawer/SideMenu.vue";
import Snackbar from "./components/common/SnackBar.vue";
import AlertDialog from "./components/dialogs/AlertDialog.vue";
import LoaderDialog from "./components/dialogs/loaderDialog.vue";
// import LoaderMainDialog from "./components/dialogs/LoaderMainDialog.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "App",
  components: {
    // Header,
    // SideMenu,
    Loading,
    Snackbar,
    AlertDialog,
    LoaderDialog,
    // LoaderMainDialog,
  },
  data: () => ({
    //
    logo: localStorage.getItem("logo"),
    default: true,
    user: {
      access_level: 1,
      name: "TEST",
    },
  }),
  watch: {
    $route() {
      if (this.$route.meta.layout == "no-header") {
        this.default = false;
      } else {
        this.user = JSON.parse(localStorage.getItem("user"));
        this.default = true;
      }
    },
  },
  mounted() {
    if (this.$route.meta.layout == "no-header") {
      this.default = false;
    } else {
      this.user = JSON.parse(localStorage.getItem("user"));
      this.default = true;
    }
  },
  methods: {
    logout() {
      localStorage.removeItem("user_token");
      localStorage.removeItem("user");
      this.$router.push("/login");
    },
    handleMaskClick() {
      this.$refs.drawerLayout.toggle(false);
    },
    handleToggleDrawer(e) {
      this.$refs.drawerLayout.toggle(e);
    },
  },
};
</script>
<style lang='scss'>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.side-bar-outer {
  background: rgb(240, 240, 240);
  max-width: 150px;
  flex: 0 0 120px;
  height: 100%;
  /* margin: 15px; */
  margin: 0;
  padding: 15px;
}
.side-bar {
  background: #fff;
  height: 100%;
  margin: 0 0 25px 0;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 6px #00000029;
  .c-logo img {
    border-radius: 50%;
    box-shadow: 0px 3px 6px #00000029;
    padding: 2px;
    width: 70px;
    height: 70px;
    margin-top: 18px;
  }
  .logo-dash {
    margin-top: auto;
  }
  .list-link {
    margin-top: 0px;
  }
  .list-nav {
    .list-item {
      display: block;
      padding: 18px 10px;
      // border-bottom: 0.75px solid #ccc;
      // margin: 5px 0;
      &:hover {
        background: #000;
        // border-bottom: 0px solid #ccc;
        i {
          color: #fff !important;
        }
      }
    }
    .list-item.router-link-active {
      background: #000;
      // border-bottom: 0px solid #ccc;
      i {
        color: #fff !important;
      }
    }
    // .list-item.router-link-exact-active {
    //   background: #000;
    //   i {
    //     color: #fff !important;
    //   }
    // }
  }
}
</style>
<style lang="scss">
a {
  color: inherit !important;
  text-decoration: none !important;
}
html {
  overflow-x: hidden !important;
}
body {
  overflow-x: hidden !important;
}
.v-application--wrap {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
.not-viewable {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: none;
}
@media screen and (min-width: 600px) and (max-width: 900px) {
  .not-viewable {
    display: flex;
    text-align: center;
    padding: 50px;
  }
  .not-viewable.mobile {
    display: none;
    // text-align: center;
    // padding: 50px;
  }
  .app-container {
    display: none !important;
  }
  .app-no-header {
    display: none !important;
  }
}
@media screen and (min-width: 0px) and (max-width: 599px) {
  .not-viewable.mobile {
    display: flex;
    text-align: center;
    padding: 50px;
  }
  .app-container {
    display: none !important;
  }
  .app-no-header {
    display: none !important;
  }
}

.content-wrap {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
.drawer-wrap {
  background: #fff;
}
.drawer-layout {
  background: #ffffff !important;
}
.app-no-header {
  background: #ffffff;
  width: 100%;
  min-height: 100vh;
  margin: auto;
}
.app-container {
  min-height: 100vh;
  display: flex;
  background: rgb(240, 240, 240);
  .page {
    margin: 0;
    height: 100%;
    flex-grow: 1;
    // left: 160px;
    min-height: 100vh;
    background: #fff;
  }
  .drawer-wrap {
    background: #fff;
  }
  .drawer-layout {
    background: #ffffff;
  }
  .content-wrap {
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }
  #content-wrap {
    background: #ffffff;
    // max-width: 1024px;
    // margin: auto;
    // padding: 15px;
  }
}
</style>
